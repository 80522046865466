import {Button, Card, Modal, Tab} from 'semantic-ui-react';
import {Fragment, useState} from 'react';
import {Report} from '../screens/reporter/components/Report';
import {ReportMembersModal} from './ReportMembersModal';
import {ReportInterface} from '../interfaces/report.interface';
import {useDeleteReportMutation, useUpdateReportMutation} from '../apis/reports.api';
import {ReportStatusEnum} from '../interfaces/report-status.enum';
import {ConfirmModal} from './ConfirmModal';

export function ReportModal({report, open, onClose}: {
  report: ReportInterface;
  open: boolean;
  onClose: () => void;
}) {

  const [reportMembersOpen, setReportMembersOpen] = useState(false);
  const [updateReport] = useUpdateReportMutation();
  const [deleteReport] = useDeleteReportMutation();

  return (
    <Modal open={!!report} onClose={() => onClose()} style={{overflow: 'hidden'}}>
      <i className="icon icon-close-window icon--secondary" onClick={() => onClose()} />
      <Tab
        menu={{
          tabular: true,
          style: {marginBottom: 0},
        }}
        as={Fragment}
        panes={[
          {
            menuItem: 'Report',
            render: () => (
              <div className="report-modal">
                <Report reportUuid={report.uuid} header={false} controls={false} editable={false} />
                <Card className="report-modal__actions">
                  <Card.Content>
                    <p>Add to report</p>
                    <Button
                      content="Members"
                      onClick={() => setReportMembersOpen(true)}
                      fluid
                      primary
                      className="mb-4"
                    />
                    <p>Actions</p>
                    {report.status === ReportStatusEnum.DONE ? (
                      <Button className="w-100 mr-2 mb-2" primary disabled>
                        <i className="icon-ok-sm" style={{height: 12}} /> Resolved
                      </Button>
                    ) : (
                      <>
                        {report.status === ReportStatusEnum.NEW && (
                          <Button
                            className="w-100 mr-2 mb-2"
                            content="Take to work"
                            primary
                            onClick={() =>
                              updateReport({
                                uuid: report.uuid,
                                projectUuid: report.projectUuid,
                                status: ReportStatusEnum.IN_WORK,
                              })
                            }
                          />
                        )}
                        {report.status === ReportStatusEnum.IN_WORK && (
                          <Button
                            className="w-100 mr-2 mb-2"
                            content="Send to review"
                            primary
                            onClick={() =>
                              updateReport({
                                uuid: report.uuid,
                                projectUuid: report.projectUuid,
                                status: ReportStatusEnum.REVIEW,
                              })
                            }
                          />
                        )}
                        {report.status === ReportStatusEnum.REVIEW && (
                          <Button
                            className="w-100 mr-2 mb-2"
                            content="Mark as resolved"
                            primary
                            onClick={() =>
                              updateReport({
                                uuid: report.uuid,
                                projectUuid: report.projectUuid,
                                status: ReportStatusEnum.DONE,
                              })
                            }
                          />
                        )}
                      </>
                    )}
                    <ConfirmModal
                      onConfirm={() => {
                        deleteReport({
                          projectUuid: report.projectUuid,
                          reportUuid: report.uuid as string,
                        });
                      }}
                      danger
                      title="Delete report"
                      text="Do you really want to delete this report?"
                    >
                      <Button content="Delete" fluid secondary />
                    </ConfirmModal>
                  </Card.Content>
                </Card>
                <ReportMembersModal
                  report={report}
                  open={reportMembersOpen}
                  onClose={() => setReportMembersOpen(false)}
                />
              </div>
            ),
          },
          // {
          //   menuItem: 'Activity',
          //   render: () => <></>,
          // },
        ]}
      />
    </Modal>
  );
}
