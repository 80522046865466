import {createApi} from '@reduxjs/toolkit/dist/query/react';
import {TeamInterface} from '../interfaces/team.interface';
import {NewTeamInterface} from '../interfaces/new-team.interface';
import {baseQuery} from '../common/api';
import {transformPlan} from './subscription.api';

export function transformTeam(data: {[key: string]: any}): TeamInterface {
  return {
    uuid: data.uuid,
    title: data.title,
    createdAt: data.created_at,
    updatedAt: data.updated_at,
    createdBy: data.create_by,
    userStatus: data.user_status,
    membersCount: data.members_count,
    isPro: data.is_pro,
    plan: data.plan ? transformPlan(data.plan) : undefined,
  };
}

export const teamsApi = createApi({
  reducerPath: 'teamsApi',
  baseQuery,
  tagTypes: ['Teams'],
  endpoints: (builder) => ({
    /**
     * Find teams
     */
    findTeams: builder.query<
      {
        data: TeamInterface[];
      },
      void
    >({
      query: () => `teams`,
      transformResponse: (response: any) => {
        return {
          ...response,
          data: response.data.map(transformTeam),
        };
      },
      providesTags: (result) => {
        return (result?.data.map((item) => ({type: 'Teams' as const, id: item.uuid})) || []).concat([
          {
            type: 'Teams',
            id: 'LIST',
          },
        ]);
      },
    }),

    /**
     * Get a single team
     */
    getTeam: builder.query<
      {
        data: TeamInterface;
      },
      string
    >({
      query: (uuid) => `teams/${uuid}`,
      transformResponse: (response: any) => ({
        ...response,
        data: transformTeam(response.data),
      }),
      providesTags: (_result, error, uuid) => {
        return error ? [] : [{type: 'Teams', id: uuid}];
      },
    }),

    /**
     * Create a new team
     */
    createTeam: builder.mutation<
      {
        data: TeamInterface;
      },
      NewTeamInterface
    >({
      query: (body) => ({
        url: `teams`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{type: 'Teams', id: 'LIST'}],
      async onQueryStarted(_patch, {queryFulfilled}) {
        try {
          await queryFulfilled;
        } catch (e) {}
      },
    }),

    /**
     * Update an existing team
     */
    updateTeam: builder.mutation<
      {
        data: TeamInterface;
      },
      Pick<TeamInterface, 'uuid' | 'title'>
    >({
      query: ({uuid, ...patch}) => ({
        url: `teams/${uuid}`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: (_result, _error, {uuid}) => [{type: 'Teams', id: uuid}],
    }),

    /**
     * Delete the team
     */
    deleteTeam: builder.mutation<void, string>({
      query: (uuid) => ({
        url: `teams/${uuid}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{type: 'Teams', id: 'LIST'}],
      async onQueryStarted(_patch, {queryFulfilled}) {
        try {
          await queryFulfilled;
        } catch (e) {}
      },
    }),
  }),
});

export const {useFindTeamsQuery, useGetTeamQuery, useCreateTeamMutation, useUpdateTeamMutation, useDeleteTeamMutation} =
  teamsApi;
