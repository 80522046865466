import {Button, Dropdown} from 'semantic-ui-react';
import {useAppDispatch, useAppSelector} from '../../../hooks';
import {closeProject} from '../../../slices/projects.slice';
import {
  useAddToFavoritesMutation,
  useDeleteProjectMutation,
  useFindProjectsQuery,
  useRemoveFromFavoritesMutation,
  useUpdateProjectMutation,
} from '../../../apis/projects.api';
import {ProjectInterface, ProjectStatusEnum, ProjectUserStatusEnum} from '../../../interfaces/project.interface';
import {dropDownMenuReposition} from '../../../common/helpers';
import {useNavigate} from "react-router-dom";
import {ConfirmModal} from "../../../components/ConfirmModal";

export function ProjectMenu({project}: {project: ProjectInterface}) {
  const dispatch = useAppDispatch();
  const activeProjectUuid = useAppSelector((state) => state.projects.active);
  const projects = useAppSelector((state) => state.projects.all);
  // const {data, error, isLoading, refetch} = useFindProjectsQuery();
  const [updateProject, updateProjectResult] = useUpdateProjectMutation();
  const [deleteProject] = useDeleteProjectMutation();
  const [addToFavorites] = useAddToFavoritesMutation();
  const [removeFromFavorites] = useRemoveFromFavoritesMutation();
  const navigate = useNavigate();

  // const projects = data.data;
  // const project = projects.find((item) => item.uuid === activeProjectUuid);

  if (!activeProjectUuid || !project) {
    return <></>;
  }

  function archive() {
    dispatch(closeProject(activeProjectUuid));
    updateProject({
      uuid: activeProjectUuid,
      status: ProjectStatusEnum.ARCHIVED,
    });
    navigate('/projects');
  }

  function activate() {
    updateProject({
      uuid: activeProjectUuid,
      status: ProjectStatusEnum.ARCHIVED,
    });
    // dispatch(updateProject(activeProjectUuid, {status: 'active'}));
  }

  function favorite(value: boolean) {
    if (value) {
      addToFavorites(activeProjectUuid);
    } else {
      removeFromFavorites(activeProjectUuid);
    }
  }

  function del() {
    if (activeProjectUuid) {
      dispatch(closeProject(activeProjectUuid));
      deleteProject(activeProjectUuid);
      navigate('/projects');
    }
  }

  return (
    <Dropdown
      trigger={
        <Button className="button--darken">
          <i className="icon icon-dots" />
        </Button>
      }
      icon={false}
      direction="left"
      onOpen={dropDownMenuReposition}
    >
      <Dropdown.Menu className="inverse">
        {project.favorite && <Dropdown.Item onClick={() => favorite(false)}>Remove from favorites</Dropdown.Item>}
        {!project.favorite && <Dropdown.Item onClick={() => favorite(true)}>Favorite this project</Dropdown.Item>}
        <Dropdown.Divider />
        <Dropdown.Item onClick={() => {
          dispatch(closeProject(activeProjectUuid));
          navigate('/projects');
        }}>Close project</Dropdown.Item>
        {project.userStatus === ProjectUserStatusEnum.OWNER ? (
          <ConfirmModal
            onConfirm={() => archive()}
            danger
            title="Delete project"
            text="Do you really want to delete this project?"
          >
            <Dropdown.Item>Delete</Dropdown.Item>
          </ConfirmModal>
        ) : (
          <ConfirmModal
            onConfirm={() => del()}
            danger
            title="Leave project"
            text="Do you really want to leave this project?"
          >
            <Dropdown.Item>Leave project</Dropdown.Item>
          </ConfirmModal>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}
