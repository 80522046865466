import {createApi} from '@reduxjs/toolkit/dist/query/react';
import {baseQuery} from '../common/api';
import {TeamMemberInterface} from '../interfaces/team-member.interface';

function transformResponse(data: {[key: string]: any}): TeamMemberInterface {
  return {
    uuid: data.uuid,
    teamUuid: data.team_uuid,
    userUuid: data.user_uuid,
    userEmail: data.user_email,
    userName: data.user_name,
    invitationEmail: data.invitation_email,
    status: data.status,
    createdAt: data.created_at,
  };
}

export const teamMembersApi = createApi({
  reducerPath: 'teamMembersApi',
  baseQuery,
  tagTypes: ['TeamMember'],
  endpoints: (builder) => ({
    findTeamMembers: builder.query<
      {
        data: TeamMemberInterface[];
      },
      string
    >({
      query: (teamUuid) => `teams/${teamUuid}/members`,
      providesTags: ['TeamMember'],
      transformResponse: (response: any) => {
        return {
          ...response,
          data: response.data.map(transformResponse),
        };
      },
    }),

    addTeamMember: builder.mutation<
      {
        data: TeamMemberInterface;
      },
      {
        teamUuid: string;
        email: string;
      }
    >({
      query: ({teamUuid, ...body}) => ({
        url: `teams/${teamUuid}/members`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_result, error) => {
        return error ? [] : ['TeamMember'];
      },
      async onQueryStarted(_patch, {queryFulfilled}) {
        try {
          await queryFulfilled;
        } catch (e) {}
      },
    }),

    removeTeamMember: builder.mutation<
      void,
      {
        teamUuid: string;
        memberUuid: string;
      }
    >({
      query: ({teamUuid, memberUuid}) => ({
        url: `teams/${teamUuid}/members/${memberUuid}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, error) => {
        return error ? [] : ['TeamMember'];
      },
      async onQueryStarted(_patch, {queryFulfilled}) {
        try {
          await queryFulfilled;
        } catch (e) {}
      },
    }),
  }),
});

export const {useFindTeamMembersQuery, useAddTeamMemberMutation, useRemoveTeamMemberMutation} = teamMembersApi;
