import logo from '../../../assets/icons/logo-grey.svg';
import {LoaderDots} from './LoaderDots';

export function LoaderLogo({absolute}: {absolute?: boolean}) {
  return (
    <div className={`loader-logo ${absolute ? 'loader-logo--absolute' : ''}`}>
      <img src={logo} style={{width: 64, height: 64}} />
      <LoaderDots />
    </div>
  );
}
