import {Fragment, useEffect, useState} from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {Modal, Tab} from 'semantic-ui-react';
import {KanbanBoard} from '../../../components/KanbanBoard';
import {ReportInterface} from '../../../interfaces/report.interface';
import {Report} from './Report';
import {useNavigate, useParams} from "react-router-dom";
import {useAppSelector} from "../../../hooks";
import {ReportModal} from "../../../components/ReportModal";

export function ReportsBoard() {
  const [activeReport, setActiveReport] = useState<ReportInterface>();
  const reports = useAppSelector((state) => state.reports.reports);
  const {projectUuid, reportUuid} = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (reportUuid) {
      setActiveReport(reports.find((report) => report.uuid === reportUuid));
    }
  }, [reportUuid]);

  useEffect(() => {
    if (activeReport) {
      navigate(`/projects/${activeReport.projectUuid}/${activeReport.uuid}`, {replace: true});
    } else {
      navigate(`/projects/${projectUuid}`, {replace: true});
    }
  }, [activeReport]);

  return (
    <div style={{padding: 0, height: '100%'}}>
      <PerfectScrollbar>
        <KanbanBoard onClick={(e, report) => setActiveReport(report)}/>
      </PerfectScrollbar>

      {activeReport && (
        <ReportModal
          report={reports.find((item) => item.uuid === activeReport?.uuid)}
          open
          onClose={() => setActiveReport(undefined)}
        />
      )}
    </div>
  );
}
