export enum ProjectStatusEnum {
  ACTIVE = 1,
  ARCHIVED = 0,
}

export enum ProjectUserStatusEnum {
  OWNER = 'owner',
  MEMBER = 'member',
}

export interface ProjectInterface {
  uuid: string;
  name: string;
  url: string;
  preview: string | null;
  favorite: boolean;
  createdAt: string;
  updatedAt: string;
  viewedAt: string;
  status: ProjectStatusEnum;
  userUuid: string;
  userStatus: ProjectUserStatusEnum;
  shared: boolean;
  teamUuid?: string;
  teamName?: string;
  membersCount?: string;
  memberUuids?: string[];
}
