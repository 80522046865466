import {useEffect, useState} from 'react';
import {Dimmer, List, Message, Modal} from 'semantic-ui-react';
import {ProjectInterface, ProjectUserStatusEnum} from '../interfaces/project.interface';
import {LoaderDots} from '../screens/homepage/components/LoaderDots';
import {useAppDispatch} from '../hooks';
import {Abbr} from './Abbr';
import {disableGlobalErrorHandler, enableGlobalErrorHandler} from '../slices/global.slice';
import {useFindTeamsQuery} from "../apis/teams.api";
import {useFindTeamMembersQuery} from "../apis/team-members.api";
import {TeamMemberStatusEnum} from "../interfaces/team.interface";

export function ShareProjectModal({
  project,
  open,
  onClose,
}: {
  project: ProjectInterface;
  open: boolean;
  onClose: () => void;
}) {
  const teamsQuery = useFindTeamsQuery();
  const [selectedTeam, setSelectedTeam] = useState<string | undefined>(project.teamUuid);
  const teamMembersQuery = useFindTeamMembersQuery(selectedTeam as string, {skip: !selectedTeam || !open});
  const [memberUuids, setMemberUuids] = useState(project.memberUuids);
  const dispatch = useAppDispatch();
  const isLoading = teamsQuery.isFetching || teamMembersQuery.isFetching;
  const isError = teamsQuery.isError || teamMembersQuery.isError;
  const error = ((teamsQuery.error || teamMembersQuery.error) as any)?.data?.message || 'Unknown error';
  const isAdmin = project.userStatus === ProjectUserStatusEnum.OWNER;

  useEffect(() => {
    return () => {
      dispatch(enableGlobalErrorHandler());
    };
  }, []);

  useEffect(() => {
    if (open) {
      teamMembersQuery.refetch();
      dispatch(disableGlobalErrorHandler());
    } else {
      dispatch(enableGlobalErrorHandler());
    }
  }, [open]);

  return (
    <>
      <Modal style={{display: 'flex'}} size="mini" open={open} onClose={() => onClose()} closeOnDimmerClick>
        <Dimmer active={isLoading}>
          <LoaderDots/>
        </Dimmer>
        <Modal.Header>
          Project team
          <i className="icon icon-close-window icon--dark" onClick={() => onClose()}/>
        </Modal.Header>
        <Modal.Content style={{maxHeight: '60vh', overflowY: 'auto', padding: 0}}>
          {isError && <Message error content={error} />}
          <List>
            {teamMembersQuery?.data?.data
              .filter((member) => memberUuids?.indexOf(member.uuid) !== -1 || member.status === TeamMemberStatusEnum.OWNER || project.shared)
              .map((member) => (
                <List.Item className="pl-3 pr-3">
                  <Abbr name={member.userName || member.userEmail || member.invitationEmail || 'Unknown'}/>
                  <List.Content>
                    <List.Header>{member.userName || member.userEmail || member.invitationEmail}</List.Header>
                    <List.Description>
                      {member.status === TeamMemberStatusEnum.OWNER && 'team owner'}
                      {member.status === TeamMemberStatusEnum.ADMIN && 'team admin'}
                      {member.status === TeamMemberStatusEnum.MEMBER && 'member'}
                      {member.status === TeamMemberStatusEnum.INVITED && 'invited'}
                    </List.Description>
                  </List.Content>
                </List.Item>
              ))}
          </List>
        </Modal.Content>
      </Modal>
    </>
  );
}
