import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface AuthStateInterface {
  loggedIn: boolean;
  userUuid?: string;
  username?: string;
  name?: string;
  token?: string;
  cookieAccepted: boolean;
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: <AuthStateInterface>{
    loggedIn: false,
    cookieAccepted: false,
  },
  reducers: {
    setUser(
      state: AuthStateInterface,
      action: PayloadAction<{
        userUuid: string;
        username: string;
        token: string;
        name: string;
      }>
    ) {
      state.loggedIn = true;
      state.userUuid = action.payload.userUuid;
      state.username = action.payload.username;
      state.name = action.payload.name;
      state.token = action.payload.token;
      state.cookieAccepted = true;
    },
    unsetUser(state: AuthStateInterface) {
      state.loggedIn = false;
      state.userUuid = undefined;
      state.username = undefined;
      state.name = undefined;
      state.token = undefined;
      state.cookieAccepted = false;
    },
    acceptCookie(state: AuthStateInterface) {
      state.cookieAccepted = true;
    }
  },
});

export const {setUser, unsetUser, acceptCookie} = authSlice.actions;

export const authReducer = authSlice.reducer;
