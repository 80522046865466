import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {createLayout, deleteLayout, getLayouts, updateLayout} from '../thunks/layout-compare.thunks';
import {DesignLayoutInterface} from '../interfaces/design-layout.interface';

export interface LayoutCompareSliceInterface {
  layouts: DesignLayoutInterface[];
  activeLayout?: DesignLayoutInterface;
  image: string | undefined;
  locked: boolean;
  invert: boolean;
  opacity: number;
  position: {
    x: number;
    y: number;
  };
}

export const layoutCompareSlice = createSlice({
  name: 'layout-compare',
  initialState: <LayoutCompareSliceInterface>{
    layouts: [],
    activeLayout: undefined,
    image: undefined,
    locked: false,
    invert: false,
    opacity: 0.5,
    position: {
      x: 0,
      y: 0,
    },
  },
  reducers: {
    setImage(state: LayoutCompareSliceInterface, action: PayloadAction<string | undefined>) {
      state.image = action.payload;
    },
    setActiveLayout(state: LayoutCompareSliceInterface, action: PayloadAction<string | undefined>) {
      const layout = state.layouts.find((item) => item.uuid === action.payload);
      state.activeLayout = layout;
      if (!layout) {
        state.image = undefined;
        state.locked = false;
        state.invert = false;
        state.opacity = 0.5;
        state.position.x = 0;
        state.position.y = 0;
      } else {
        state.image = layout.image;
        state.locked = layout.locked;
        state.invert = layout.invert;
        state.opacity = layout.opacity;
        state.position.x = layout.x;
        state.position.y = layout.y;
      }
    },
    setLocked(state: LayoutCompareSliceInterface, action: PayloadAction<boolean>) {
      state.locked = action.payload;
    },
    setInvert(state: LayoutCompareSliceInterface, action: PayloadAction<boolean>) {
      state.invert = action.payload;
    },
    setOpacity(state: LayoutCompareSliceInterface, action: PayloadAction<number>) {
      state.opacity = action.payload;
    },
    setPosition(state: LayoutCompareSliceInterface, action: PayloadAction<{x: number; y: number}>) {
      state.position = action.payload;
    },
    reset(state: LayoutCompareSliceInterface) {
      state.image = undefined;
      state.locked = false;
      state.invert = false;
      state.opacity = 0.5;
      state.position.x = 0;
      state.position.y = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLayouts.fulfilled, (state, action) => {
      state.layouts = action.payload;
    });
    builder.addCase(createLayout.fulfilled, (state, action) => {
      state.layouts.unshift(action.payload);
    });
    builder.addCase(updateLayout.fulfilled, (state, action) => {
      const layout = action.payload;
      const index = state.layouts.findIndex((item) => item.uuid === layout.uuid);
      state.layouts[index] = layout;
    });
    builder.addCase(deleteLayout.fulfilled, (state, action) => {
      const layoutUuid = action.payload;
      const index = state.layouts.findIndex((item) => item.uuid === layoutUuid);
      state.layouts.splice(index, 1);
    });
  },
});

export const {setImage, setActiveLayout, setLocked, setInvert, setOpacity, setPosition, reset} = layoutCompareSlice.actions;

export const layoutCompareReducer = layoutCompareSlice.reducer;
