import {useEffect, useState} from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {Menu} from 'semantic-ui-react';
import {useAppDispatch, useAppSelector} from '../../../hooks';
import {setBoardPageUrlFilter, setCollapsedBoardPages} from '../../../slices/reports.slice';
import {ReportStatusEnum} from '../../../interfaces/report-status.enum';
import {pagesTreeFromReports} from '../../../common/helpers';
import {ReportInterface} from '../../../interfaces/report.interface';

export function Reports() {
  const reports = useAppSelector((state) => state.reports.reports);
  const pageUrlFilter = useAppSelector((state) => state.reports.boardPageUrlFilter);
  const collapsed = useAppSelector((state) => state.reports.collapsedBoardPages);
  const dispatch = useAppDispatch();
  const [pagesTree, setPagesTree] = useState<any>([]);

  useEffect(() => {
    const filtered: ReportInterface[] = reports.filter((report) => report.status !== ReportStatusEnum.DELETED);
    setPagesTree(pagesTreeFromReports(filtered));
  }, [reports]);

  function toggle(key: string, state: boolean) {
    dispatch(setCollapsedBoardPages({...collapsed, [key]: state}));
  }

  function renderItems(pages, level = 0) {
    return pages.map((page, i) => {
      const key = `i-${level}-${i}`;

      return (
        <>
          <Menu.Item
            key={key}
            active={page.url === pageUrlFilter}
            onClick={() => {
              dispatch(setBoardPageUrlFilter(page.url));
              toggle(key, false);
            }}
            title={page.hint}
            disabled={!page.reportsCount}
            style={{paddingLeft: level * 21 + 15, position: 'relative'}}
          >
            {page.children.length > 0 && level > 0 && (
              <i
                className={`icon icon--sm icon-angle ${collapsed[key] ? 'icon-angle--right' : ''}`.trim()}
                onClick={(e) => {
                  e.stopPropagation();
                  toggle(key, !collapsed[key]);
                }}
                style={{WebkitMaskSize: 9, width: 26, height: 26, margin: '-5px 0 -5px -26px'}}
              />
            )}
            <i className="icon icon--sm icon-file" />
            <span>{page.title}</span>
            <div className="text-secondary pl-2" style={{marginLeft: 'auto'}}>
              {page.reportsCount || ''}
            </div>
          </Menu.Item>
          {page.children.length > 0 && !collapsed[key] && (
            <Menu key={`m-${i}-${level}`} vertical>
              {renderItems(page.children, level + 1)}
            </Menu>
          )}
        </>
      );
    });
  }

  return (
    <PerfectScrollbar>
      <Menu key={`m-0`} vertical fluid>
        <Menu.Item key={0} active={!pageUrlFilter} onClick={() => dispatch(setBoardPageUrlFilter())}>
          <i className="icon icon--sm icon-inbox" style={{WebkitMaskSize: 18}} />
          <span>All reports</span>
          <div className="text-secondary pl-2" style={{marginLeft: 'auto'}}>
            {reports.filter(
              (report) =>
                [ReportStatusEnum.NEW, ReportStatusEnum.IN_WORK, ReportStatusEnum.REVIEW].indexOf(report.status) !== -1
            ).length || 'empty'}
          </div>
        </Menu.Item>
        {renderItems(pagesTree)}
      </Menu>
    </PerfectScrollbar>
  );
}
