import {EventBus} from './event-bus';
import {invokeInMain} from './helpers';

export async function browserSendBack() {
  return invokeInMain('browser-send-back');
}

export async function browserBringTop() {
  return invokeInMain('browser-bring-top');
}

export async function browserHide() {
  return invokeInMain('browser-hide');
}

export function browserShow(sync = false) {
  return invokeInMain('browser-show', {
    setBounds: !sync,
  });
}

export function browserBack() {
  EventBus.sendToMain('browser-back');
}

export function browserForward() {
  EventBus.sendToMain('browser-forward');
}

export function browserReload() {
  EventBus.sendToMain('browser-reload');
}

export function browserNavigate(url: string) {
  EventBus.sendToMain('browser-navigate', {url});
}

export function openExternal(url: string, activate = true) {
  window.electron.openExternal(url, activate);
}

export function openReporterPage(url: string, activate = true) {
  window.electron.openExternal(`${process.env.REACT_APP_ACCOUNT_APP_URL}${url}`, activate);
}

export function getBrowserContainerSize(): {width: number; height: number} {
  const browserContainer = document.getElementById('browser-container');
  if (!browserContainer) {
    throw new Error('browser-container must present');
  }
  const style = window.getComputedStyle(browserContainer, null);

  return {
    width: parseInt(style.getPropertyValue('width'), 10),
    height: parseInt(style.getPropertyValue('height'), 10),
  };
}
