import {Middleware} from '@reduxjs/toolkit';
import {AnyAction, Dispatch} from 'redux';
import {setSync, setSyncError} from '../slices/global.slice';

export const SyncStatusMiddleware: Middleware =
  ({dispatch, getState}: {dispatch: Dispatch; getState: any}) =>
  (next: Dispatch<AnyAction>) =>
  (action: any) => {
    const state = getState();

    if (action.type.endsWith('/pending')) {
      if (state.global.syncError !== '') {
        dispatch(setSyncError(''));
      }
      if (state.global.sync !== true) {
        dispatch(setSync(true));
      }
    }
    if (action.type.endsWith('/fulfilled')) {
      if (state.global.syncError !== '') {
        dispatch(setSyncError(''));
      }
      if (state.global.sync !== false) {
        dispatch(setSync(false));
      }
    }

    if (action.type.endsWith('/rejected')) {
      if (
        state.global.syncError === '' &&
        action.error.name !== 'ConditionError' &&
        state.global.globalErrorHandlerEnabled
      ) {
        if (action.type.indexOf('/executeMutation/') !== -1) {
          dispatch(setSyncError('Error during synchronization. Changes was not saved. Try again.'));
        } else {
          dispatch(setSyncError('Error during synchronization. Try again.'));
        }
      }
      if (state.global.sync !== false) {
        dispatch(setSync(false));
      }
    }
    return next(action);
  };
