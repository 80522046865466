import {createApi} from '@reduxjs/toolkit/dist/query/react';
import {baseQuery} from '../common/api';
import {UserPlanInterface} from '../interfaces/user-plan-interface';
import {TeamPlanInterface} from '../interfaces/team-plan-interface';

export function transformPlan(data: {[key: string]: any}): UserPlanInterface | TeamPlanInterface {
  return {
    plan: data.plan,
    status: data.status,
    endsAt: data.ends_at,
    quantity: data.quantity,
    maxVideoDuration: data.max_video_duration,
    projectsLimit: data.projects_limit,
    projectsLimitUsed: data.projects_limit_used,
    storageLimitMB: data.storage_limit_mb,
    storageLimitMBUsed: data.storage_limit_mb_used,
    startedAt: data.started_at,
    lastPayment: data.last_payment,
    nextPayment: data.next_payment,
    paymentMethod: data.payment_method,
    receipts: data.receipts?.map((item: any) => ({
      name: item.name,
      createdAt: item.created_at,
      amount: item.amount,
      tax: item.tax,
      quantity: item.quantity,
      receiptUrl: item.receipt_url,
    })),
  };
}

export const subscriptionApi = createApi({
  reducerPath: 'subscriptionApi',
  baseQuery,
  endpoints: (builder) => ({
    plan: builder.query<
      UserPlanInterface | TeamPlanInterface,
      {
        userUuid?: string;
        teamUuid?: string;
        extended?: boolean;
      }
    >({
      query: ({userUuid, teamUuid, extended} = {}) => ({
        url: `subscriptions/summary`,
        method: 'GET',
        params: {
          account: userUuid,
          team: teamUuid,
          extended,
        },
      }),
      transformResponse: (response: any) => transformPlan(response),
    }),
  }),
});

export const {usePlanQuery} = subscriptionApi;
