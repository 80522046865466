import {unsetUser} from '../slices/auth.slice';

export const LogoutMiddleware =
  ({dispatch, getState}: {dispatch: any; getState: any}) =>
  (next: any) =>
  (action: any) => {
    if (action.type.endsWith('/rejected') && action.payload?.status === 401) {
      dispatch(unsetUser());
    }
    return next(action);
  };
