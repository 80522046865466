import {Link} from "react-router-dom";
import logo from "../../assets/icons/logo-transparent.svg";
import {SyncIndicator} from "../../components/SyncIndicator";
import {useEffect} from "react";
import {useAppDispatch} from "../../hooks";
import {disableGlobalErrorHandler, enableGlobalErrorHandler} from "../../slices/global.slice";

export function Header() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(disableGlobalErrorHandler());
    return () => {
      dispatch(enableGlobalErrorHandler());
    }
  }, []);

  return (
    <div className="homepage__header">
      <div className="homepage__header-logo">
        <Link to="/">
          <img src={logo} />
        </Link>
      </div>
      <SyncIndicator />
    </div>
  );
}
