import {v4} from 'uuid';
// import {db} from '../db';
import {DesignLayoutInterface} from '../interfaces/design-layout.interface';

export class LayoutComparisonService {
  static async getLayouts(projectUuid: string): Promise<DesignLayoutInterface[]> {
    // const layouts = (await db.get(`layoutComparison`)) || {};
    return [];
  }

  static async createLayout(projectUuid: string, data: DesignLayoutInterface): Promise<DesignLayoutInterface> {
    // const layouts = (await db.get(`layoutComparison`)) || {};
    // layouts[projectUuid] = layouts[projectUuid] || [];
    // data.uuid = v4();
    // layouts[projectUuid].push(data);
    // await db.set({layoutComparison: layouts});
    return undefined;
  }

  static async updateLayout(
    projectUuid: string,
    layoutUuid: string,
    data: Partial<DesignLayoutInterface>
  ): Promise<DesignLayoutInterface> {
    // const layouts = (await db.get(`layoutComparison`)) || {};
    // layouts[projectUuid] = layouts[projectUuid] || [];
    // const layout = layouts[projectUuid].find((item: DesignLayoutInterface) => item.uuid === layoutUuid);
    // if (!layout) {
    //   throw new Error('Layout not found');
    // }
    // Object.assign(layout, data);
    // await db.set({layoutComparison: layouts});
    return undefined;
  }

  static async deleteLayout(projectUuid: string, layoutUuid: string): Promise<string> {
    // const layouts = (await db.get(`layoutComparison`)) || {};
    // layouts[projectUuid] = layouts[projectUuid] || [];
    // const layoutIndex = layouts[projectUuid].findIndex((item: DesignLayoutInterface) => item.uuid === layoutUuid);
    // layouts[projectUuid].splice(layoutIndex, 1);
    // await db.set({layoutComparison: layouts});
    return layoutUuid;
  }
}
