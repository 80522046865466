import {useEffect, useState} from 'react';
import {sortBy} from 'lodash';
import {Card, Dropdown} from 'semantic-ui-react';
import {useAppDispatch, useAppSelector} from '../../../hooks';
import {openProject} from '../../../slices/projects.slice';
import {ProjectInterface, ProjectStatusEnum, ProjectUserStatusEnum} from '../../../interfaces/project.interface';
import {NewProject} from './NewProject';
import {formatDateTime, pluralize, relativeTime} from '../../../common/helpers';
import {Screenshot} from '../../../components/Screenshot';
import {
  useAddToFavoritesMutation,
  useDeleteProjectMutation,
  useRemoveFromFavoritesMutation,
  useUpdateProjectMutation,
} from '../../../apis/projects.api';
import {Link} from "react-router-dom";
import {ConfirmModal} from "../../../components/ConfirmModal";
import {TruncatedText} from "../../../components/TruncatedText";
import './ProjectList.scss';

export function ProjectList({projects: allProjects}: { projects: ProjectInterface[] }) {
  const search = useAppSelector((state) => state.projects.search);
  const section = useAppSelector((state) => state.projects.section);
  const activeTeam = useAppSelector((state) => state.projects.team);
  const dispatch = useAppDispatch();
  const [contextMenuVisible, setContextMenuVisible] = useState<string | null>(null);
  const [contextMenuPosition, setContextMenuPosition] = useState([0, 0]);
  const [updateProject] = useUpdateProjectMutation();
  const [addToFavorites] = useAddToFavoritesMutation();
  const [removeFromFavorites] = useRemoveFromFavoritesMutation();
  const [deleteProject] = useDeleteProjectMutation();

  let filteredProjects = allProjects
    .filter((project) => project.name.toLowerCase().indexOf(search.toLowerCase()) !== -1)
    .filter(
      (project) => project.status === (section === 'archive' ? ProjectStatusEnum.ARCHIVED : ProjectStatusEnum.ACTIVE)
    )
    .filter((project) => (section === 'favorites' ? !!project.favorite : true))
    .filter((project) => (section === 'personal' ? !project.teamUuid : true))
    .filter((project) => (activeTeam ? project.teamUuid === activeTeam : true));

  filteredProjects = sortBy(
    filteredProjects,
    (project) => -new Date(section === 'recent' ? project.viewedAt : project.createdAt)
  );

  useEffect(() => {
    document.querySelector('.screen__main .ps')?.scrollTo(0, 0);
  }, [section]);

  function onCardContextMenu(e: any, project: ProjectInterface) {
    e.preventDefault();
    e.stopPropagation();
    setContextMenuVisible(project.uuid);
    setContextMenuPosition([e.nativeEvent.layerX, e.nativeEvent.layerY]);
  }

  function archive(project: ProjectInterface) {
    updateProject({
      uuid: project.uuid,
      status: ProjectStatusEnum.ARCHIVED,
    });
  }

  function activate(project: ProjectInterface) {
    updateProject({
      uuid: project.uuid,
      status: ProjectStatusEnum.ACTIVE,
    });
  }

  function favorite(project: ProjectInterface, value: boolean) {
    if (value) {
      addToFavorites(project.uuid);
    } else {
      removeFromFavorites(project.uuid);
    }
  }

  function onCardClick(project: ProjectInterface) {
    updateProject({
      uuid: project.uuid,
      viewedAt: new Date().toISOString(),
    });
  }

  function del(project: ProjectInterface) {
    deleteProject(project.uuid);
  }

  return (
    <>
      {/*<div className="card-list">*/}
      {/*  <NewProject />*/}
      {/*</div>*/}
      <div className="card-list project-list">
        {filteredProjects.map((project, i) => (
          <Card
            key={i}
            className={`card--hovered ${project.status === ProjectStatusEnum.ARCHIVED ? 'card--inactive' : ''}`}
            as={project.status === ProjectStatusEnum.ARCHIVED ? undefined : Link}
            to={project.status === ProjectStatusEnum.ARCHIVED ? undefined : project.uuid}
            onClick={() => onCardClick(project)}
            onContextMenu={(e: any) => onCardContextMenu(e, project)}
          >
            <Screenshot src={project.preview}/>
            <Card.Content>
              <div className="title title--mb">
                <div>
                  <div className="mb-1">{project.name}</div>
                  <div className="text-secondary font-sm">
                    {section === 'recent'
                      ? `Viewed ${relativeTime(new Date(project.viewedAt || project.createdAt))}`
                      : `Edited ${relativeTime(new Date(project.createdAt || project.updatedAt))}`}
                  </div>
                </div>
              </div>
              <div className="meta">
                <div style={{display: 'flex', alignItems: 'center', maxWidth: 120}}>
                  {project.teamName ? (
                    <i className="icon icon--sm icon-people icon--secondary" />
                  ) : (
                    <i className="icon icon--sm icon-inbox icon--secondary" />
                  )}
                  &nbsp;
                  <TruncatedText>{project.teamName || 'Personal projects'}</TruncatedText>
                </div>
                {project.teamUuid && (project.shared ? 'shared' : pluralize(project.membersCount, 'member'))}
              </div>
            </Card.Content>

            <Dropdown
              style={{position: 'absolute', left: contextMenuPosition[0], top: contextMenuPosition[1]}}
              icon={false}
              open={contextMenuVisible === project.uuid}
              onClose={() => setContextMenuVisible(null)}
            >
              <Dropdown.Menu className="inverse" style={{minWidth: 200}}>
                {project.status === ProjectStatusEnum.ARCHIVED ? (
                  <>
                    <Dropdown.Item onClick={(e) => {
                      e.preventDefault();
                      activate(project);
                    }}>Restore</Dropdown.Item>
                    <ConfirmModal
                      onConfirm={() => del(project)}
                      danger
                      title="Delete project"
                      text="Do you really want to delete this project forever?"
                    >
                      <Dropdown.Item>Delete forever</Dropdown.Item>
                    </ConfirmModal>
                  </>
                ) : (
                  <>
                    <Dropdown.Item as={Link} to={project.uuid}>Open</Dropdown.Item>
                    <Dropdown.Divider/>
                    {project.favorite && (
                      <Dropdown.Item onClick={(e) => {
                        e.preventDefault();
                        favorite(project, false)
                      }}>Remove from favorites</Dropdown.Item>
                    )}
                    {!project.favorite && (
                      <Dropdown.Item onClick={(e) => {
                        e.preventDefault();
                        favorite(project, true);
                      }}>Add to your favorites</Dropdown.Item>
                    )}
                    {project.userStatus === ProjectUserStatusEnum.OWNER ? (
                      <ConfirmModal
                        onConfirm={() => archive(project)}
                        danger
                        title="Delete project"
                        text="Do you really want to delete this project?"
                      >
                        <Dropdown.Item>Delete</Dropdown.Item>
                      </ConfirmModal>
                    ) : (
                      <ConfirmModal
                        onConfirm={() => del(project)}
                        danger
                        title="Leave project"
                        text="Do you really want to leave this project?"
                      >
                        <Dropdown.Item>Leave project</Dropdown.Item>
                      </ConfirmModal>
                    )}
                  </>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </Card>
        ))}
      </div>
    </>
  );
}
