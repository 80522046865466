import {createApi} from '@reduxjs/toolkit/dist/query/react';
import {baseQuery} from '../common/api';
import {ProjectMemberInterface} from '../interfaces/project-member.interface';

function transformResponse(data: {[key: string]: any}): ProjectMemberInterface {
  return {
    uuid: data.uuid,
    projectUuid: data.project_uuid,
    userUuid: data.user_uuid,
    userEmail: data.user_email,
    userName: data.user_name,
    invitationEmail: data.invitation_email,
    status: data.status,
    createdAt: data.created_at,
  };
}

export const projectMembersApi = createApi({
  reducerPath: 'projectMembersApi',
  baseQuery,
  endpoints: (builder) => ({
    findProjectMembers: builder.query<
      {
        data: ProjectMemberInterface[];
      },
      string
    >({
      query: (projectUuid) => `projects/${projectUuid}/members`,
      transformResponse: (response: any) => {
        return {
          ...response,
          data: response.data.map(transformResponse),
        };
      },
    }),

    addProjectMember: builder.mutation<
      {
        data: ProjectMemberInterface;
      },
      {
        projectUuid: string;
        email: string;
      }
    >({
      query: ({projectUuid, ...body}) => ({
        url: `projects/${projectUuid}/members`,
        method: 'POST',
        body,
      }),
      async onQueryStarted({projectUuid}, {dispatch, queryFulfilled}) {
        try {
          const {data} = await queryFulfilled;
          dispatch(
            projectMembersApi.util.updateQueryData('findProjectMembers', projectUuid, (draft) => {
              draft.data.push(transformResponse(data.data));
            })
          );
        } catch (e) {
          console.error(e);
        }
      },
    }),

    removeProjectMember: builder.mutation<
      void,
      {
        projectUuid: string;
        uuid: string;
      }
    >({
      query: ({projectUuid, uuid}) => ({
        url: `projects/${projectUuid}/members/${uuid}`,
        method: 'DELETE',
      }),
      async onQueryStarted({projectUuid, uuid}, {dispatch, queryFulfilled}) {
        await queryFulfilled;
        dispatch(
          projectMembersApi.util.updateQueryData('findProjectMembers', projectUuid, (draft) => {
            draft.data = draft.data.filter((member) => member.uuid !== uuid);
          })
        );
      },
    }),
  }),
});

export const {useFindProjectMembersQuery, useAddProjectMemberMutation, useRemoveProjectMemberMutation} =
  projectMembersApi;
