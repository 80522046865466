import {createApi} from '@reduxjs/toolkit/dist/query/react';
import {isUndefined, omitBy} from 'lodash';
import {baseQuery} from '../common/api';
import {ReportInterface} from '../interfaces/report.interface';
import {NewReportInterface} from '../interfaces/new-report.interface';

function transformReport(data: {[key: string]: any}): ReportInterface | Partial<ReportInterface> {
  return omitBy(
    {
      uuid: data.uuid,
      projectUuid: data.project_uuid,
      title: data.title,
      description: data.description,
      expectedBehavior: data.expected_behavior,
      actualBehavior: data.actual_behavior,
      stepsToReproduce: data.steps_to_reproduce,
      dueDate: data.due_date,
      pageTitle: data.page_title,
      pageUrl: data.page_url,
      resolution: data.resolution,
      userAgent: data.user_agent,
      steps: data.steps,
      status: data.status_id,
      position: data.position,
      attachments: data.attachment,
      memberUuids: data.member_uuids,
      createdAt: data.created_at,
      updatedAt: data.updated_at,
    },
    isUndefined
  );
}

function transformRequestBody(body: Partial<ReportInterface>): object {
  return omitBy(
    {
      project_uuid: body.projectUuid,
      title: body.title,
      description: body.description,
      expected_behavior: body.expectedBehavior,
      actual_behavior: body.actualBehavior,
      steps_to_reproduce: body.stepsToReproduce,
      due_date: body.dueDate,
      page_title: body.pageTitle,
      page_url: body.pageUrl,
      resolution: body.resolution
        ? {
            x: body.resolution[0],
            y: body.resolution[1],
          }
        : undefined,
      user_agent: body.userAgent,
      attachment: body.attachments,
      steps: body.steps,
      status_id: body.status,
      position: body.position,
      member_uuids: body.memberUuids,
    },
    isUndefined
  );
}

export const reportsApi = createApi({
  reducerPath: 'reportsApi',
  baseQuery,
  // tagTypes: ['Report'],
  endpoints: (builder) => ({
    /**
     * Find project reports
     */
    findReports: builder.query<
      {
        data: ReportInterface[];
      },
      string
    >({
      query: (uuid) => `projects/${uuid}/reports`,
      transformResponse: (response: any) => {
        return {
          ...response,
          data: response.data.map(transformReport),
        };
      },
      // providesTags: (result, error, arg) => {
      //   return result?.data
      //     ? [...result.data.map(({uuid}) => ({type: 'Report' as const, id: uuid})), 'Report']
      //     : ['Report'];
      // },
    }),

    getReport: builder.query<
      {
        data: ReportInterface;
      },
      {
        projectUuid: string;
        reportUuid: string;
      }
    >({
      query: ({projectUuid, reportUuid}) => `projects/${projectUuid}/reports/${reportUuid}`,
      // providesTags: (result, error, uuid) => [{type: 'Report', id: uuid}],
    }),

    createReport: builder.mutation<
      {
        data: ReportInterface;
      },
      NewReportInterface
    >({
      query: (body) => ({
        url: `projects/${body.projectUuid}/reports`,
        method: 'POST',
        body: transformRequestBody(body),
      }),
      async onQueryStarted(body, {dispatch, queryFulfilled}) {
        const patchResult = dispatch(
          reportsApi.util.updateQueryData('findReports', body.projectUuid, (draft) => {
            draft.data.unshift({
              ...body,
              position: -1,
              createdAt: new Date(),
              updatedAt: new Date(),
            } as any);
          })
        );
        try {
          const {data} = await queryFulfilled;
          dispatch(
            reportsApi.util.updateQueryData('findReports', body.projectUuid, (draft) => {
              const report = transformReport(data.data);
              draft.data[0] = report as ReportInterface;
              draft.data.forEach((item, index) => {
                if (item.status === report.status) {
                  draft.data[index].position += 1;
                }
              });
            })
          );
        } catch (e) {
          patchResult.undo();
        }
      },
    }),

    updateReport: builder.mutation<
      {
        data: ReportInterface;
      },
      Partial<ReportInterface> & Pick<ReportInterface, 'uuid' | 'projectUuid'>
    >({
      query: ({uuid, projectUuid, ...body}) => ({
        url: `projects/${projectUuid}/reports/${uuid}`,
        method: 'PATCH',
        body: transformRequestBody(body),
      }),
      // invalidatesTags: (result, error, arg) => [{type: 'Report', id: arg.uuid}],
      async onQueryStarted({uuid, projectUuid, ...patch}, {dispatch, queryFulfilled}) {
        const patchResult = dispatch(
          reportsApi.util.updateQueryData('findReports', projectUuid, (draft) => {
            const report = draft.data.find((item) => item.uuid === uuid);
            if (report) {
              if (patch.status !== undefined || patch.position !== undefined) {
                const fromPosition = report.position;
                const toPosition = patch.position !== undefined ? patch.position : report.position;

                if (patch.status !== undefined && report?.status !== patch.status) {
                  const prevStatus = report.status;
                  draft.data.forEach((item, index) => {
                    if (item.status === prevStatus && item.position > fromPosition) {
                      draft.data[index].position -= 1;
                    }
                  });
                  draft.data.forEach((item, index) => {
                    if (item.status === patch.status && item.position >= toPosition) {
                      draft.data[index].position += 1;
                    }
                  });
                } else {
                  if (fromPosition < toPosition) {
                    draft.data.forEach((item, index) => {
                      if (
                        item.status === patch.status &&
                        item.position >= fromPosition + 1 &&
                        item.position <= toPosition
                      ) {
                        draft.data[index].position -= 1;
                      }
                    });
                  } else {
                    draft.data.forEach((item, index) => {
                      if (
                        item.status === patch.status &&
                        item.position >= toPosition &&
                        item.position <= fromPosition - 1
                      ) {
                        draft.data[index].position += 1;
                      }
                    });
                  }
                }
              }

              Object.assign(report, patch);
            }
          })
        );
        try {
          const {data} = await queryFulfilled;
          dispatch(
            reportsApi.util.updateQueryData('findReports', projectUuid, (draft) => {
              const reportIndex = draft.data.findIndex((item) => item.uuid === uuid);
              Object.assign(draft.data[reportIndex], transformReport(data.data));
            })
          );
        } catch (e) {
          patchResult.undo();
        }
      },
    }),

    deleteReport: builder.mutation<
      {
        data: {uuid: string};
      },
      {
        projectUuid: string;
        reportUuid: string;
      }
    >({
      query: ({projectUuid, reportUuid}) => ({
        url: `projects/${projectUuid}/reports/${reportUuid}`,
        method: 'DELETE',
      }),
      async onQueryStarted({projectUuid, reportUuid}, {dispatch, queryFulfilled}) {
        const patchResult = dispatch(
          reportsApi.util.updateQueryData('findReports', projectUuid, (draft) => {
            draft.data = draft.data.filter((item) => item.uuid !== reportUuid);
          })
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),
  }),
});

export const {
  useFindReportsQuery,
  useGetReportQuery,
  useCreateReportMutation,
  useUpdateReportMutation,
  useDeleteReportMutation,
} = reportsApi;
