import {Button, Dimmer, Form, Modal} from 'semantic-ui-react';
import {ReactElement, useEffect, useState} from 'react';
import {useResetPasswordMutation} from '../../apis/auth.api';
import {LoaderDots} from '../homepage/components/LoaderDots';
import {Toast} from '../../components/Toast';

export function ForgotPasswordModal({children}: {children: ReactElement}) {
  const [resetPassword, {isLoading, isSuccess, error}] = useResetPasswordMutation();
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [showToast, setShowToast] = useState(false);

  function submit() {
    resetPassword({email});
  }

  useEffect(() => {
    if (isSuccess) {
      setOpen(false);
      setShowToast(true);
    }
  }, [isSuccess]);

  return (
    <>
      {showToast && (
        <Toast isError content="We have sent you a link for reset password" onClose={() => setShowToast(false)} />
      )}
      <span onClick={() => setOpen(true)}>{children}</span>
      <Modal size="tiny" open={open} onClose={() => setOpen(false)} closeOnDimmerClick>
        <Dimmer active={isLoading}>
          <LoaderDots />
        </Dimmer>
        <Modal.Header>
          Reset password
          <i className="icon icon-close-window icon--dark" onClick={() => setOpen(false)} />
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Input
              autoFocus
              value={email}
              onChange={(_e, {value}) => setEmail(value)}
              error={(error as any)?.data?.errors?.email || (error as any)?.data?.message}
              placeholder="Enter your email"
            />
            <Button primary disabled={!email.trim()} fluid content="Reset" onClick={() => submit()} />
          </Form>
        </Modal.Content>
      </Modal>
    </>
  );
}
