export function Toast({
  content,
  onClose,
  isError,
  position = 'top',
  inverse = false
}: { content: string; onClose?: () => void; isError?: boolean, position?: 'top' | 'bottom', inverse?: boolean }) {
  return (
    <div className={`toast ${isError ? 'toast--error' : ''} ${position === 'bottom' ? 'toast--bottom' : ''}  ${inverse ? 'toast--inverse' : ''}`}>
      <span dangerouslySetInnerHTML={{
        __html: content
      }}/>
      <i className="icon icon-close icon--dark" onClick={() => onClose && onClose()}/>
    </div>
  );
}
