// import './LoaderCircle.scss';

export function LoaderCircle() {
  return (
    <div className="loader-circle">
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}
