export enum ProjectMemberStatusEnum {
  INVITED = 1,
  ACTIVE = 2,
}

export interface ProjectMemberInterface {
  uuid: string;
  projectUuid: string;
  userUuid: string;
  userEmail: string;
  userName: string;
  invitationEmail: string;
  status: ProjectMemberStatusEnum;
  createdAt: string;
}
