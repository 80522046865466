import {Input} from 'semantic-ui-react';
import {User} from '../../../components/User';
import {useAppDispatch, useAppSelector} from '../../../hooks';
import {setSearch} from '../../../slices/projects.slice';
import {SyncIndicator} from '../../../components/SyncIndicator';
import './Header.scss';
import logo from "../../../assets/icons/logo-transparent.svg";
import {Link} from "react-router-dom";

export function Header() {
  const search = useAppSelector((state) => state.projects.search);
  const dispatch = useAppDispatch();

  return (
    <div className="homepage__header">
      <div className="homepage__header-logo">
        <Link to="/projects">
          <img src={logo} />
        </Link>
      </div>
      <Input
        value={search}
        className="icon inverse"
        onChange={(_e, data) => dispatch(setSearch(data.value))}
        placeholder="Search projects..."
      >
        <i className="icon icon--sm icon-search" />
        <input />
      </Input>
      <SyncIndicator />
      <User />
    </div>
  );
}
