import {combineReducers, configureStore} from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER} from 'redux-persist';
import thunk from 'redux-thunk';
import {createLogger} from 'redux-logger';
import {reporterReducer} from './slices/reporter.slice';
import {projectsReducer} from './slices/projects.slice';
import {reportsReducer} from './slices/reports.slice';
import {globalReducer} from './slices/global.slice';
import {layoutCompareReducer} from './slices/layout-compare.slice';
import {projectsApi} from './apis/projects.api';
import {authApi} from './apis/auth.api';
import {authReducer} from './slices/auth.slice';
import {reportsApi} from './apis/reports.api';
import {projectPagesApi} from './apis/project-pages.api';
import {favoritePagesApi} from './apis/favorite-pages.api';
import {SyncStatusMiddleware} from './middlewares/sync-status.middleware';
import {favoritePagesReducer} from './slices/favorite-pages.slice';
import {projectPagesReducer} from './slices/project-pages.slice';
import {LogoutMiddleware} from './middlewares/logout.middleware';
import {projectMembersApi} from './apis/project-members.api';
import {subscriptionApi} from "./apis/subscription.api";
import {teamMembersApi} from "./apis/team-members.api";
import {teamsApi} from "./apis/teams.api";

const persistConfig = {
  key: 'root',
  version: 2,
  storage,
  whitelist: ['auth'],
};

const combinedReducer = combineReducers({
  global: globalReducer,
  auth: authReducer,
  reporter: reporterReducer,
  projects: projectsReducer,
  reports: reportsReducer,
  layoutCompare: layoutCompareReducer,
  favoritePages: favoritePagesReducer,
  projectPages: projectPagesReducer,
  [projectsApi.reducerPath]: projectsApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [reportsApi.reducerPath]: reportsApi.reducer,
  [projectPagesApi.reducerPath]: projectPagesApi.reducer,
  [favoritePagesApi.reducerPath]: favoritePagesApi.reducer,
  [projectMembersApi.reducerPath]: projectMembersApi.reducer,
  [subscriptionApi.reducerPath]: subscriptionApi.reducer,
  [teamMembersApi.reducerPath]: teamMembersApi.reducer,
  [teamsApi.reducerPath]: teamsApi.reducer,
});

const rootReducer = (state: any, action: any) => {
  return combinedReducer(action.type === 'auth/unsetUser' ? undefined : state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const logger = createLogger({
  collapsed: true,
});

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      thunk,
      logger,
      SyncStatusMiddleware,
      LogoutMiddleware,
      authApi.middleware,
      projectsApi.middleware,
      reportsApi.middleware,
      projectPagesApi.middleware,
      favoritePagesApi.middleware,
      projectMembersApi.middleware,
      subscriptionApi.middleware,
      teamMembersApi.middleware,
      teamsApi.middleware,
    ),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
