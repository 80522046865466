import {Reports} from './Reports';

export function SidebarLeft() {

  return (
    <>
      <header className="sidebar__header">Reports</header>
      <Reports />
    </>
  );
}
