import {Segment} from 'semantic-ui-react';
import {CookieConsent} from "./CookieConsent";
import {useAppSelector} from "../hooks";

export function BaseLayout({
  headerContents,
  sidebarLeftContents,
  sidebarRightContents,
  mainContents,
  className,
  waiting,
}: {
  headerContents?: JSX.Element;
  sidebarLeftContents?: JSX.Element;
  sidebarRightContents?: JSX.Element;
  mainContents?: JSX.Element;
  className?: string;
  waiting?: boolean;
}) {
  const cookieAccepted = useAppSelector((state) => state.auth.cookieAccepted);

  return (
    <div className={`screen ${className || ''} ${waiting ? 'waiting' : ''}`.trim()}>
      {headerContents && <div className="screen__header">{headerContents}</div>}
      <Segment.Group horizontal className="screen__content">
        {sidebarLeftContents && (
          <Segment basic className="sidebar sidebar--left">
            {sidebarLeftContents}
          </Segment>
        )}
        <Segment basic className="screen__main">
          {mainContents || ''}
        </Segment>
        {sidebarRightContents && (
          <Segment basic className="sidebar sidebar--right">
            {sidebarRightContents}
          </Segment>
        )}
      </Segment.Group>
      {!cookieAccepted && <CookieConsent />}
    </div>
  );
}
