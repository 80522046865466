import {useEffect, useState} from 'react';
import {Button, Card} from 'semantic-ui-react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {useAppDispatch, useAppSelector} from '../../../hooks';
import {formatDateTime} from '../../../common/helpers';
import {browserNavigate, browserShow, openReporterPage} from '../../../common/browser';
import {Steps} from './Steps';
import {ReportStatusEnum} from '../../../interfaces/report-status.enum';
import {ReportContextMenu} from './ReportContextMenu';
import {Gallery} from '../../../components/gallery/Gallery';
import {Screenshot} from '../../../components/Screenshot';
import {ReportInterface} from '../../../interfaces/report.interface';
import {useUpdateReportMutation} from '../../../apis/reports.api';
import {setReportsBoardVisible} from '../../../slices/reporter.slice';
import {Abbr} from '../../../components/Abbr';
import {ReportMembers} from '../../../components/ReportMembers';
import {ReportDateLabel} from "../../../components/ReportDateLabel";
import {Linkify} from "../../../components/Linkify";

export function Report({
                         hidden,
                         reportUuid,
                         header,
                         controls,
                         editable,
                         showPageLink,
                       }: {
  hidden?: boolean;
  reportUuid: string;
  header: boolean;
  controls: boolean;
  editable: boolean;
  showPageLink?: boolean;
}) {
  const reports = useAppSelector((state) => state.reports.reports);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [updateReport] = useUpdateReportMutation();
  const [report, setReport] = useState<ReportInterface>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    setReport(reports.find((item) => item.uuid === reportUuid));
  }, [reports]);

  if (!report || report.status === ReportStatusEnum.DELETED) {
    return <div/>;
  }

  function showGallery(index: number) {
    setActiveIndex(index);
    setIsGalleryOpen(true);
  }

  if (hidden) {
    return <></>;
  }

  return (
    <>
      {header !== false && <header className="sidebar__header">Report</header>}
      <div className="report-view" style={{overflowY: 'auto', flex: '1 1 auto'}}>
        <PerfectScrollbar>
          <Card>
            <Card.Content className="text-content">
              <label>Title</label>
              <p style={{whiteSpace: 'pre-wrap', wordBreak: 'break-word'}}>{report.title || '—'}</p>

              {controls !== false && (
                <div className="mt-4 mb-4">
                  {report.status === ReportStatusEnum.DONE ? (
                    <Button className="w-100 mr-2" primary disabled>
                      <i className="icon-ok-sm" style={{height: 12}}/> Resolved
                    </Button>
                  ) : (
                    <Button
                      className="w-100 mr-2"
                      content="Mark as resolved"
                      primary
                      onClick={() =>
                        updateReport({
                          uuid: report.uuid,
                          projectUuid: report.projectUuid,
                          status: ReportStatusEnum.DONE,
                        })
                      }
                    />
                  )}
                  <ReportContextMenu report={report} editable={editable}>
                    <Button className="button--secondary button--context-menu"/>
                  </ReportContextMenu>
                </div>
              )}

              {report.memberUuids?.length > 0 && (
                <>
                  <label>Members</label>
                  <ReportMembers report={report}/>
                </>
              )}

              {showPageLink !== false && (
                <>
                  <label>Page</label>
                  <p>
                    <a
                      className="link"
                      href={report.pageUrl}
                      target="_blank"
                    >
                      {report.pageTitle || report.pageUrl || '—'}
                    </a>
                  </p>
                </>
              )}

              <div style={{flexDirection: 'column'}}>
                <label className="mb-2">Description</label>
                <p style={{whiteSpace: 'pre-wrap', wordBreak: 'break-word'}} className="selectable mb-0">
                  <Linkify>{report.description || '—'}</Linkify>
                </p>

                <label className="mt-4 mb-2">Expected behavior</label>
                <p style={{whiteSpace: 'pre-wrap', wordBreak: 'break-word'}} className="selectable mb-0">
                  <Linkify>{report.expectedBehavior || '—'}</Linkify>
                </p>

                <label className="mt-4 mb-2">Actual behavior</label>
                <p style={{whiteSpace: 'pre-wrap', wordBreak: 'break-word'}} className="selectable mb-0">
                  <Linkify>{report.actualBehavior || '—'}</Linkify>
                </p>

                <label className="mt-4 mb-2">Steps to reproduce</label>
                <p style={{whiteSpace: 'pre-wrap', wordBreak: 'break-word'}} className="selectable mb-0">
                  <Linkify>{report.stepsToReproduce || '—'}</Linkify>
                </p>

                {report.dueDate && (
                  <>
                    <label className="mt-4 mb-2">Due date</label>
                    <p className="mb-0">
                      <span className="mr-2">{new Date(report.dueDate).toLocaleDateString()}</span>
                      <ReportDateLabel report={report} />
                    </p>
                  </>
                )}
              </div>

              <label>Attachments</label>
              {report.attachments?.length ? (
                <>
                  <Gallery
                    activeIndex={activeIndex}
                    isOpen={isGalleryOpen}
                    items={report.attachments}
                    onClose={() => setIsGalleryOpen(false)}
                  />
                  <Screenshot.List columns={2}>
                    {report.attachments.map((screenshot, i) => (
                      <Screenshot clickable key={i} src={screenshot} onClick={() => showGallery(i)}/>
                    ))}
                  </Screenshot.List>
                </>
              ) : (
                <p>—</p>
              )}

              <label>Dimensions</label>
              <p>
                <>
                  {!report.resolution[0] || !report.resolution[1] ? (
                    'All dimensions'
                  ) : (
                    `${report.resolution[0]} x ${report.resolution[1]}`
                  )}
                </>
                , {report.userAgent}
              </p>

              <Card.Meta className="mt-4 mb-0">
                {formatDateTime(new Date(report.createdAt))}
                <a
                  className="icon icon-link link"
                  href={`/projects/${report.projectUuid}/${report.uuid}`}
                  target="_blank"
                />
              </Card.Meta>
            </Card.Content>
          </Card>
          <header className="sidebar__header pl-3">History</header>
          {report.steps?.filter((step) => typeof step === 'object').length ? (
            <Steps steps={report?.steps?.filter((step) => typeof step === 'object')} reverse/>
          ) : (
            <p className="ml-3">—</p>
          )}
        </PerfectScrollbar>
      </div>
    </>
  );
}
