import {ReactElement, useState} from 'react';
import {Dropdown} from 'semantic-ui-react';
import {ReportStatusEnum} from '../../../interfaces/report-status.enum';
import {openReporterPage} from '../../../common/browser';
import {ReportInterface} from '../../../interfaces/report.interface';
import {useAppDispatch} from '../../../hooks';
import {
  setDetails,
  setEditReportUuid,
  setReportFormVisibility,
  setScreenshots,
  setShortDescription,
} from '../../../slices/reporter.slice';
import {setActiveReportUuid} from '../../../slices/reports.slice';
import {useDeleteReportMutation, useUpdateReportMutation} from '../../../apis/reports.api';
import {dropDownMenuReposition} from '../../../common/helpers';
import {ReportMembersModal} from "../../../components/ReportMembersModal";

export function ReportContextMenu({
  children,
  report,
  editable,
}: {
  children: ReactElement;
  report: ReportInterface;
  editable: boolean;
}) {
  const dispatch = useAppDispatch();
  const [updateReport] = useUpdateReportMutation();
  const [deleteReport] = useDeleteReportMutation();
  const [reportMembersOpen, setReportMembersOpen] = useState(false);

  function edit() {
    dispatch(setEditReportUuid(report.uuid));
    dispatch(setReportFormVisibility(true));
    dispatch(setActiveReportUuid(undefined));
    dispatch(setShortDescription(report.title));
    dispatch(setDetails(report.description));
    dispatch(setScreenshots(report.attachments || []));
  }

  return (
    <>
      <Dropdown
        trigger={children}
        icon={false}
        direction="left"
        onOpen={dropDownMenuReposition}
      >
        <Dropdown.Menu className="inverse">
          {report.status === ReportStatusEnum.DONE && (
            <Dropdown.Item
              onClick={() =>
                updateReport({
                  uuid: report.uuid,
                  projectUuid: report.projectUuid,
                  status: ReportStatusEnum.NEW,
                })
              }
            >
              Mark as unresolved
            </Dropdown.Item>
          )}
          {report.status !== ReportStatusEnum.DONE && (
            <Dropdown.Item
              onClick={() =>
                updateReport({
                  uuid: report.uuid,
                  projectUuid: report.projectUuid,
                  status: ReportStatusEnum.DONE,
                })
              }
            >
              Mark as resolved
            </Dropdown.Item>
          )}
          <Dropdown.Divider />
          {editable !== false && <>
              <Dropdown.Item onClick={() => edit()}>Edit</Dropdown.Item>
              <Dropdown.Divider />
          </>}
          <Dropdown.Item onClick={() => setReportMembersOpen(true)}>Members</Dropdown.Item>
          <Dropdown.Item
            onClick={() => navigator.clipboard.writeText(`${window.location.origin}/projects/${report.projectUuid}/${report.uuid}`)}
          >
            Copy link to this report
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            onClick={() =>
              deleteReport({
                projectUuid: report.projectUuid,
                reportUuid: report.uuid as string,
              })
            }
          >
            Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      {reportMembersOpen && <ReportMembersModal report={report} open={reportMembersOpen} onClose={() => setReportMembersOpen(false)} />}
    </>
  );
}
