import {SyntheticEvent} from 'react';
import {ReportInterface} from '../interfaces/report.interface';
import {ProjectPageInterface} from '../interfaces/project-page.interface';
import {ReportStatusEnum} from '../interfaces/report-status.enum';
import {DropdownProps} from 'semantic-ui-react';
import moment from "moment";

const {language} = navigator;

export function formatDateTime(date: Date, withSeconds = false) {
  if (date.toString() === 'Invalid Date') {
    date = new Date();
  }
  return new Intl.DateTimeFormat(language, {
    dateStyle: 'short',
    timeStyle: withSeconds ? 'medium' : 'short',
  }).format(date);
}

export function relativeTime(date: Date): string {
  return moment(date).fromNow();
}

export function getFcmToken(): Promise<string> {
  // return window.electron.getFcmToken();
  return undefined;
}

export function invokeInMain(funcName: string, data?: any): Promise<any> {
  // return window.electron.ipcRenderer.invoke(funcName, data);
  return undefined;
}

export function trimUrl(url: string) {
  return url?.replace(/\/$/, '').replace(/\:\/\/www\./, '://');
}

export function pagesTreeFromReports(reports: ReportInterface[]): object {
  const result = [];
  const urls = reports.map((report) => trimUrl(report.pageUrl));
  reports.forEach(
    function (page) {
      page.pageUrl
        .split(/(?<!\/)\/(?!\/)/)
        .filter((i) => !!i)
        .reduce(function (r, k, i, kk) {
          if (!r[k]) {
            const leaf = i + 1 === kk.length;
            r[k] = {_: []};
            const url = kk.slice(0, i + 1).join('/');
            let title = kk
              .slice(1, i + 1)
              .join('/')
              .trim();
            if (title) {
              title = `/${k}`;
            } else {
              title = kk[0].replace(/^https?\:\/\//, '');
            }

            // const title = reports.find(page => page.pageUrl == url)?.pageTitle || url.replace(/^https?\:\/\//, '');

            const reportsCount = reports
              .filter((report) => trimUrl(report.pageUrl) === trimUrl(url))
              .filter(
                (report) =>
                  [ReportStatusEnum.NEW, ReportStatusEnum.IN_WORK, ReportStatusEnum.REVIEW].indexOf(report.status) !==
                  -1
              ).length;
            if (leaf) {
              // r._.push({node: k, title: page.pageTitle, url: page.pageUrl, leaf, children: r[k]._});
              r._.push({
                node: k,
                title,
                hint: page.pageTitle ? `${page.pageTitle} (${page.pageUrl})` : page.pageUrl,
                url: page.pageUrl,
                leaf,
                reportsCount,
                children: r[k]._,
              });
            } else {
              // r._.push({node: k, title: k, url: k, leaf, children: r[k]._});
              r._.push({
                node: k,
                title,
                hint: page.pageTitle ? `${page.pageTitle} (${url})` : url,
                url,
                leaf,
                reportsCount,
                children: r[k]._,
              });
            }
          }
          return r[k];
        }, this);
    },
    {_: result}
  );

  return result;
}

export function pagesTreeFromPages(pages: ProjectPageInterface[], reports: ReportInterface[] = []): object {
  const result = [];
  // const urls = reports.map((report) => report.pageUrl);
  pages.forEach(
    function (page) {
      page.url
        .split(/(?<!\/)\/(?!\/)/)
        .filter((i) => !!i)
        .reduce(function (r, k, i, kk) {
          if (!r[k]) {
            const leaf = i + 1 === kk.length;
            r[k] = {_: []};
            const url = kk.slice(0, i + 1).join('/');
            let title = kk
              .slice(1, i + 1)
              .join('/')
              .trim();
            if (title) {
              title = `/${k}`;
            } else {
              title = kk[0].replace(/^https?\:\/\//, '');
            }

            const reportsCount = reports
              .filter((report) => trimUrl(report.pageUrl) === trimUrl(url))
              .filter(
                (report) =>
                  [ReportStatusEnum.NEW, ReportStatusEnum.IN_WORK, ReportStatusEnum.REVIEW].indexOf(report.status) !==
                  -1
              ).length;
            // if (leaf) {
            r._.push({
              node: k,
              title,
              hint: page.title ? `${page.title} (${page.url})` : page.url,
              reportsCount,
              url,
              leaf,
              children: r[k]._,
            });

            // } else {
            //   r._.push({node: k, title: page.title, url: url, leaf, children: r[k]._});
            // }
          }
          return r[k];
        }, this);
    },
    {_: result}
  );

  return result;
}

export function dropDownMenuReposition(e: SyntheticEvent<HTMLElement>, data: DropdownProps) {
  if (data.direction !== 'left') {
    return;
  }

  const dropdown = e.currentTarget.closest('.dropdown');
  const width = e.currentTarget.offsetWidth;
  const height = e.currentTarget.offsetHeight;
  const menu: HTMLDivElement | null = e.currentTarget.querySelector('.menu');

  if (!menu) {
    return;
  }

  menu.style.opacity = '0';

  setTimeout(() => {
    menu.style.transform = `translateX(-100%) translateX(${width}px)`;
    const isUpward = dropdown?.classList.contains('upward');
    if (isUpward) {
      menu.style.transform += `translateY(-100%) translateY(-${height + 10}px)`;
    }
    menu.style.opacity = '1';
  }, 0);
}

export const pluralize = (count, noun, suffix = 's') => `${count} ${noun}${count !== 1 ? suffix : ''}`;
