import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ProjectPageInterface} from '../interfaces/project-page.interface';

export interface ProjectPagesStateInterface {
  pages: ProjectPageInterface[];
}
export const projectPagesSlice = createSlice({
  name: 'projectPages',
  initialState: <ProjectPagesStateInterface>{
    pages: [],
  },
  reducers: {
    setProjectPages(state: ProjectPagesStateInterface, action: PayloadAction<ProjectPageInterface[]>) {
      state.pages = action.payload;
    },
  },
});

export const {setProjectPages} = projectPagesSlice.actions;

export const projectPagesReducer = projectPagesSlice.reducer;
