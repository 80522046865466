import {LinkIt} from 'react-linkify-it';

export function Linkify({children}: {children: string}) {

  const urlRegex = /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}(\.[a-zA-Z0-9()]{1,6}\b)?(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)/;

  return (
    <LinkIt
      regex={urlRegex}
      component={(match, key) => (
        <a className="link" href={match} target="_blank" key={key}>
          {match}
        </a>
      )}
    >
      {children}
    </LinkIt>
  );
}
