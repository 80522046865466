import {Toast} from "./Toast";
import {acceptCookie} from "../slices/auth.slice";
import {useAppDispatch} from "../hooks";

export function CookieConsent() {
  const dispatch = useAppDispatch();

  return <Toast inverse position='bottom'
                content={`By using our site you agree with our <a href="${process.env.REACT_APP_PRIVACY_URL}" target="_blank" class="link">Privacy Policy</a>`}
                onClose={() => dispatch(acceptCookie())}
  />
}
