import Board, {moveCard} from './react-kanban-board';
import {useEffect, useState} from 'react';
import {ReportCard} from './ReportCard';
import {useAppDispatch, useAppSelector} from '../hooks';
import {ReportStatusEnum} from '../interfaces/report-status.enum';
import {ReportInterface} from '../interfaces/report.interface';
import {trimUrl} from '../common/helpers';
import {useUpdateReportMutation} from '../apis/reports.api';

export function KanbanBoard({onClick}) {
  const [updateReport] = useUpdateReportMutation();
  const reports = useAppSelector((state) => state.reports.reports);
  const pageUrlFilter = useAppSelector((state) => state.reports.boardPageUrlFilter);
  const [board, setBoard] = useState<any>();

  const filter = (report: ReportInterface) => {
    if (report.status === ReportStatusEnum.DELETED) {
      return false;
    }
    if (pageUrlFilter && trimUrl(report.pageUrl) !== trimUrl(pageUrlFilter)) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    setBoard({
      columns: [
        {
          id: ReportStatusEnum.NEW,
          title: 'New',
          cards: reports
            .filter((report) => report.status === ReportStatusEnum.NEW)
            .filter(filter)
            .sort((a, b) => (a.position < b.position ? -1 : 1))
            .map((report) => ({
              id: report.uuid,
              ...report,
            })),
        },
        {
          id: ReportStatusEnum.IN_WORK,
          title: 'In work',
          cards: reports
            .filter((report) => report.status === ReportStatusEnum.IN_WORK)
            .filter(filter)
            .sort((a, b) => (a.position < b.position ? -1 : 1))
            .map((report) => ({
              id: report.uuid,
              ...report,
            })),
        },
        {
          id: ReportStatusEnum.REVIEW,
          title: 'Review',
          cards: reports
            .filter((report) => report.status === ReportStatusEnum.REVIEW)
            .filter(filter)
            .sort((a, b) => (a.position < b.position ? -1 : 1))
            .map((report) => ({
              id: report.uuid,
              ...report,
            })),
        },
        {
          id: ReportStatusEnum.DONE,
          title: 'Done',
          cards: reports
            .filter((report) => report.status === ReportStatusEnum.DONE)
            .filter(filter)
            .sort((a, b) => (a.position < b.position ? -1 : 1))
            .map((report) => ({
              id: report.uuid,
              ...report,
            })),
        },
      ],
    });
  }, [reports, pageUrlFilter]);

  const emptyHintMap = {
    [ReportStatusEnum.NEW]: 'New reports',
    [ReportStatusEnum.IN_WORK]: 'Reports on development stage',
    [ReportStatusEnum.REVIEW]: 'Reports to review',
    [ReportStatusEnum.DONE]: 'Done and reviewed reports',
  };
  const emptyIconMap = {
    [ReportStatusEnum.NEW]: 'icon icon-inbox',
    [ReportStatusEnum.IN_WORK]: 'icon icon-move',
    [ReportStatusEnum.REVIEW]: 'icon icon-move',
    [ReportStatusEnum.DONE]: 'icon icon-ok',
  };

  if (!board) {
    return <></>;
  }

  return (
    <Board
      disableColumnDrag
      renderColumnHeader={({id, title, cards}) => (
        <div
          className={`react-kanban-column-header ${!cards.length ? 'react-kanban-column-header--empty' : ''}`}
          data-empty-hint={emptyHintMap[id]}
        >
          {!cards.length && <i className={`rkb-empty-icon ${emptyIconMap[id]}`} />}
          {id === ReportStatusEnum.DONE && <i className="icon icon--sm icon-ok-sm" />}
          {title}
          {id !== ReportStatusEnum.DONE && (
            <span className="react-kanban-column-header-counter">{cards.length || 'empty'}</span>
          )}
        </div>
      )}
      renderCard={(report, {removeCard, dragging}) => (
        <ReportCard report={report} editable={false}
                    // dragging={dragging}
                    onClick={(e, data) => onClick && onClick(e, data)} />
      )}
      onCardDragEnd={(report, source, destination) => {
        const updatedBoard = moveCard(board, source, destination);
        setBoard(updatedBoard);
        updateReport({
          uuid: report.uuid,
          projectUuid: report.projectUuid,
          status: destination.toColumnId,
          position: destination.toPosition,
        });
      }}
      // initialBoard={board}
    >
      {board}
    </Board>
  );
}
