import {Button, Input} from 'semantic-ui-react';
import {User} from '../../../components/User';
import {useAppDispatch, useAppSelector} from '../../../hooks';
import {setSearch} from '../../../slices/projects.slice';
import {SyncIndicator} from '../../../components/SyncIndicator';
import {ProjectMenu} from "./ProjectMenu";
import logo from '../../../assets/icons/logo-transparent.svg';
import './Header.scss';
import {Link} from "react-router-dom";
import {ProjectUserStatusEnum} from "../../../interfaces/project.interface";
import {useState} from "react";
import {ShareProjectModal} from "../../../components/ShareProjectModal";

export function Header({project, waiting}: any) {
  const search = useAppSelector((state) => state.projects.search);
  const dispatch = useAppDispatch();
  const [shareModalOpen, setShareModalOpen] = useState(false);

  return (
    <div className="homepage__header">
      <div className="homepage__header-logo">
        <Link to="/projects">
          <img src={logo} />
        </Link>
        {project?.name}
      </div>
      <SyncIndicator />
      {project && project.teamUuid && (
        <Button
          onClick={() => setShareModalOpen(true)}
          primary
          content={'Team'}
          size="mini"
          style={{borderRadius: 3, margin: '0 10px'}}
        />
      )}
      <ProjectMenu project={project} />
      {shareModalOpen && project && (
        <ShareProjectModal project={project} open onClose={() => setShareModalOpen(false)} />
      )}
    </div>
  );
}
