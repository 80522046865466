import {Button, Card} from 'semantic-ui-react';
import {ReportStatusEnum} from '../interfaces/report-status.enum';
import {formatDateTime} from '../common/helpers';
import {ReportContextMenu} from '../screens/reporter/components/ReportContextMenu';
import {ReportInterface} from "../interfaces/report.interface";
import {ReportMembers} from "./ReportMembers";
import {ReportDateLabel} from "./ReportDateLabel";

export function ReportCard({report, editable, onClick, className}: {
    report: ReportInterface;
    editable?: boolean;
    onClick?: (e, report) => void;
    className?: string;
}) {
  return (
    <Card className={`card--hovered ${className || ''}`}>
      <Card.Content onClick={(e) => onClick && onClick(e, report)}>
        <div className="title">
          {report.status === ReportStatusEnum.DONE && (
            <i className="icon icon--sm icon-ok-sm" style={{margin: -1}} />
          )}
          {report.title || 'Report w/o title'}
        </div>
        <div className="icons">
          {!report.resolution[0] || !report.resolution[1] ? (
            <span className="badge">All dimensions</span>
          ) : (
            <span className="badge">{`${report.resolution[0]} x ${report.resolution[1]}`}</span>
          )}
          {!!report.attachments?.length && <i className="icon icon--sm icon-pin" />}
          {!!report.description && <i className="icon icon--sm icon-text" />}
        </div>
        {report.memberUuids?.length > 0 && <ReportMembers report={report} className="mb-2" />}
        <div className="due-date" style={{}}>
          <ReportDateLabel report={report} />
        </div>
        <div className="meta">
          {formatDateTime(new Date(report.createdAt))}
          <ReportContextMenu report={report} editable={editable}>
            <Button className="button--context-menu button--context-menu--sm button--secondary" />
          </ReportContextMenu>
        </div>
      </Card.Content>
    </Card>
  );
}
