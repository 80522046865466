import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {min} from 'lodash';
import {EventBus} from '../common/event-bus';
import {BrowserStepInterface} from '../interfaces/browser-step.interface';
import {browserBringTop, browserSendBack, getBrowserContainerSize} from '../common/browser';
import {openProject, setActiveProject} from './projects.slice';

export interface ReporterStateInterface {
  url: string;
  pageTitle: string;
  emulateDevice: 'desktop' | 'mobile';
  compactMode: boolean;
  taskListVisible: boolean;
  reportFormVisible: boolean;
  browserWidth: number;
  browserHeight: number;
  freezeModeEnabled: boolean;
  browserModeEnabled: boolean;
  screenshotModeEnabled: boolean;
  inspectorModeEnabled: boolean;
  pencilModeEnabled: boolean;
  drawArrowModeEnabled: boolean;
  textModeEnabled: boolean;
  cropModeEnabled: boolean;
  screenRecorderModeEnabled: boolean;
  layoutCompareModeEnabled: boolean;
  reportsBoardVisible: boolean;
  shortDescription: string;
  screenshots: string[];
  details: string;
  lastSteps: BrowserStepInterface[];
  focusForm: number;
  currentScreenshot: string | undefined;
  editReportUuid: string | undefined;
  loading: boolean;
}

function enableScreenshotModeHelper(state: ReporterStateInterface) {
  state.browserModeEnabled = false;
  state.screenshotModeEnabled = true;
  EventBus.sendToMain('take-screenshot', {}, true);
  browserSendBack();
}

const initialState: ReporterStateInterface = {
  url: '',
  pageTitle: '',
  emulateDevice: 'desktop',
  compactMode: false,
  taskListVisible: true,
  reportFormVisible: false,
  browserWidth: 0,
  browserHeight: 0,
  freezeModeEnabled: false,
  browserModeEnabled: true,
  screenshotModeEnabled: false,
  inspectorModeEnabled: false,
  pencilModeEnabled: false,
  drawArrowModeEnabled: false,
  textModeEnabled: false,
  cropModeEnabled: false,
  screenRecorderModeEnabled: false,
  layoutCompareModeEnabled: false,
  reportsBoardVisible: true,
  shortDescription: '',
  screenshots: [],
  details: '',
  lastSteps: [],
  focusForm: 0,
  currentScreenshot: undefined,
  editReportUuid: undefined,
  loading: true,
};

export const reporterSlice = createSlice({
  name: 'reporter',
  initialState,
  reducers: {
    setPageTitle(state: ReporterStateInterface, action: PayloadAction<string>) {
      state.pageTitle = action.payload;
    },
    setCompactMode(state: ReporterStateInterface, action: PayloadAction<boolean>) {
      state.compactMode = action.payload;
    },
    setTaskListVisibility(state: ReporterStateInterface, action: PayloadAction<boolean>) {
      state.taskListVisible = action.payload;
      state.reportFormVisible = !action.payload;
    },
    setReportFormVisibility(state: ReporterStateInterface, action: PayloadAction<boolean>) {
      state.reportFormVisible = action.payload;
      state.taskListVisible = !action.payload;
      if (!action.payload) {
        state.editReportUuid = undefined;
      }
    },
    enableFreezeMode(state: ReporterStateInterface) {
      if (!state.freezeModeEnabled) {
        state.freezeModeEnabled = true;
        browserSendBack();
      }
    },
    disableFreezeMode(state: ReporterStateInterface) {
      if (state.freezeModeEnabled) {
        browserBringTop();
        state.freezeModeEnabled = false;
      }
    },
    enableScreenshotMode: (state: ReporterStateInterface) => {
      enableScreenshotModeHelper(state);
    },
    enableBrowserMode: (state: ReporterStateInterface) => {
      browserBringTop();
      state.browserModeEnabled = true;
      state.screenshotModeEnabled = false;
      state.inspectorModeEnabled = false;
      state.pencilModeEnabled = false;
      state.drawArrowModeEnabled = false;
      state.cropModeEnabled = false;
      state.layoutCompareModeEnabled = false;
    },
    enableInspectorMode: (
      state: ReporterStateInterface,
      action: PayloadAction<{sendToMainProcess?: boolean} | undefined>
    ) => {
      if (!state.inspectorModeEnabled && action.payload?.sendToMainProcess !== false) {
        EventBus.sendToMain('enable-inspector');
      }
      state.inspectorModeEnabled = true;
      state.browserModeEnabled = true;
      state.screenshotModeEnabled = false;
      state.pencilModeEnabled = false;
      state.drawArrowModeEnabled = false;
      state.cropModeEnabled = false;
      state.screenRecorderModeEnabled = false;
      state.layoutCompareModeEnabled = false;
      browserBringTop();
    },
    disableInspectorMode: (
      state: ReporterStateInterface,
      action: PayloadAction<{sendToMainProcess?: boolean} | undefined>
    ) => {
      if (state.inspectorModeEnabled && action.payload?.sendToMainProcess !== false) {
        EventBus.sendToMain('disable-inspector');
      }
      state.inspectorModeEnabled = false;
    },
    enableDrawArrowMode: (state: ReporterStateInterface) => {
      if (state.browserModeEnabled) {
        enableScreenshotModeHelper(state);
      }
      if (state.inspectorModeEnabled) {
        EventBus.sendToMain('disable-inspector');
      }
      state.inspectorModeEnabled = false;
      state.pencilModeEnabled = false;
      state.drawArrowModeEnabled = true;
      state.textModeEnabled = false;
      state.cropModeEnabled = false;
      state.screenRecorderModeEnabled = false;
      state.layoutCompareModeEnabled = false;
    },
    enablePencilMode: (state: ReporterStateInterface) => {
      if (state.browserModeEnabled) {
        enableScreenshotModeHelper(state);
      }
      if (state.inspectorModeEnabled) {
        EventBus.sendToMain('disable-inspector');
      }
      state.inspectorModeEnabled = false;
      state.pencilModeEnabled = true;
      state.drawArrowModeEnabled = false;
      state.textModeEnabled = false;
      state.cropModeEnabled = false;
      state.screenRecorderModeEnabled = false;
      state.layoutCompareModeEnabled = false;
    },
    enableTextMode: (state: ReporterStateInterface) => {
      if (state.browserModeEnabled) {
        enableScreenshotModeHelper(state);
      }
      if (state.inspectorModeEnabled) {
        EventBus.sendToMain('disable-inspector');
      }
      state.taskListVisible = false;
      state.reportFormVisible = true;
      state.inspectorModeEnabled = false;
      state.pencilModeEnabled = false;
      state.drawArrowModeEnabled = false;
      state.textModeEnabled = true;
      state.cropModeEnabled = false;
      state.screenRecorderModeEnabled = false;
      state.layoutCompareModeEnabled = false;
    },
    enableCropMode: (state: ReporterStateInterface) => {
      if (state.browserModeEnabled) {
        enableScreenshotModeHelper(state);
      }
      if (state.inspectorModeEnabled) {
        EventBus.sendToMain('disable-inspector');
      }
      state.inspectorModeEnabled = false;
      state.pencilModeEnabled = false;
      state.drawArrowModeEnabled = false;
      state.textModeEnabled = false;
      state.cropModeEnabled = true;
      state.screenRecorderModeEnabled = false;
      state.layoutCompareModeEnabled = false;
    },
    enableScreenRecorderMode: (state: ReporterStateInterface) => {
      if (state.inspectorModeEnabled) {
        EventBus.sendToMain('disable-inspector');
      }
      browserBringTop();
      state.browserModeEnabled = true;
      state.screenshotModeEnabled = false;
      state.inspectorModeEnabled = false;
      state.pencilModeEnabled = false;
      state.drawArrowModeEnabled = false;
      state.cropModeEnabled = false;
      state.screenRecorderModeEnabled = true;
      state.layoutCompareModeEnabled = false;
    },
    disableScreenRecorderMode: (state: ReporterStateInterface) => {
      state.screenRecorderModeEnabled = false;
    },
    enableLayoutCompareMode: (state: ReporterStateInterface) => {
      if (state.inspectorModeEnabled) {
        EventBus.sendToMain('disable-inspector');
      }
      browserBringTop();
      state.browserModeEnabled = true;
      state.screenshotModeEnabled = false;
      state.inspectorModeEnabled = false;
      state.pencilModeEnabled = false;
      state.drawArrowModeEnabled = false;
      state.cropModeEnabled = false;
      state.screenRecorderModeEnabled = false;
      state.layoutCompareModeEnabled = true;
    },
    disableLayoutCompareMode: (state: ReporterStateInterface) => {
      state.layoutCompareModeEnabled = false;
    },
    updateLastScreenshot(state: ReporterStateInterface, action: PayloadAction<string>) {
      if (state.screenshots.length) {
        const screenshots = [...state.screenshots];
        screenshots[screenshots.length - 1] = action.payload;
        state.screenshots = screenshots;
      } else {
        state.screenshots = [action.payload];
      }
    },
    updateScreenshot(state: ReporterStateInterface, action: PayloadAction<{index: number; data: string}>) {
      if (state.screenshots[action.payload.index] !== undefined) {
        const screenshots = [...state.screenshots];
        screenshots[action.payload.index] = action.payload.data;
        state.screenshots = screenshots;
      }
    },
    addScreenshot(state: ReporterStateInterface, action: PayloadAction<string>) {
      state.screenshots.push(action.payload);
    },
    removeScreenshot(state: ReporterStateInterface, action: PayloadAction<number>) {
      state.screenshots.splice(action.payload, 1);
    },
    deleteLastScreenshot(state: ReporterStateInterface) {
      state.screenshots.splice(-1, 1);
    },
    setScreenshots(state: ReporterStateInterface, action: PayloadAction<string[]>) {
      state.screenshots = action.payload;
    },
    setUrl(state: ReporterStateInterface, action: PayloadAction<string>) {
      console.trace('111')
      state.url = action.payload;
    },
    setShortDescription(state: ReporterStateInterface, action: PayloadAction<string>) {
      state.shortDescription = action.payload;
    },
    setDetails(state: ReporterStateInterface, action: PayloadAction<string>) {
      state.details = action.payload;
    },
    resetReport(state: ReporterStateInterface, action: PayloadAction<{showBrowser: boolean}>) {
      if (state.inspectorModeEnabled) {
        EventBus.sendToMain('disable-inspector');
      }
      state.editReportUuid = undefined;
      state.shortDescription = '';
      state.details = '';
      state.screenshots = [];
      state.browserModeEnabled = true;
      state.screenshotModeEnabled = false;
      state.drawArrowModeEnabled = false;
      state.inspectorModeEnabled = false;
      state.pencilModeEnabled = false;
      state.textModeEnabled = false;
      state.cropModeEnabled = false;
      state.screenRecorderModeEnabled = false;
      state.layoutCompareModeEnabled = false;
      state.taskListVisible = true;
      state.reportFormVisible = false;
      if (action.payload?.showBrowser !== false) {
        browserBringTop();
      }
    },
    setResolution(state: ReporterStateInterface, action: PayloadAction<{width: number; height: number}>) {
      const {width, height} = getBrowserContainerSize();
      const scaleX = width / action.payload.width;
      const scaleY = height / action.payload.height;
      let scale = 1;

      if (scaleX < 1 || scaleY < 1) {
        scale = min([scaleX, scaleY]) as number;
      }

      state.browserWidth = Math.round(action.payload.width * scale);
      state.browserHeight = Math.round(action.payload.height * scale);
      state.emulateDevice = action.payload.width <= 1024 ? 'mobile' : 'desktop';

      EventBus.sendToMain('set-browser-resolution', {
        width: action.payload.width,
        height: action.payload.height,
        scale,
        emulateDevice: state.emulateDevice,
      });
    },
    setLastSteps(state: ReporterStateInterface, action: PayloadAction<BrowserStepInterface[]>) {
      state.lastSteps = action.payload;
    },
    setFocusForm(state: ReporterStateInterface, action: PayloadAction<boolean>) {
      state.focusForm = action.payload === false ? 0 : state.focusForm + 1;
    },
    setCurrentScreenshot(state: ReporterStateInterface, action: PayloadAction<string | undefined>) {
      state.currentScreenshot = action.payload;
    },
    setEditReportUuid(state: ReporterStateInterface, action: PayloadAction<string | undefined>) {
      state.editReportUuid = action.payload;
    },
    setReportsBoardVisible(state: ReporterStateInterface, action: PayloadAction<boolean>) {
      state.reportsBoardVisible = action.payload;
    },
    setLoading(state: ReporterStateInterface, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    resetReportsState(state: ReporterStateInterface) {
      state = {...initialState};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(openProject, () => initialState);
    builder.addCase(setActiveProject, () => initialState);
  },
});

export const {
  setPageTitle,
  setCompactMode,
  setReportFormVisibility,
  setTaskListVisibility,
  enableScreenshotMode,
  enableBrowserMode,
  enableDrawArrowMode,
  enableCropMode,
  updateLastScreenshot,
  updateScreenshot,
  addScreenshot,
  removeScreenshot,
  setScreenshots,
  setUrl,
  setShortDescription,
  setDetails,
  resetReport,
  enableInspectorMode,
  disableInspectorMode,
  enablePencilMode,
  enableTextMode,
  setResolution,
  deleteLastScreenshot,
  setLastSteps,
  enableFreezeMode,
  disableFreezeMode,
  setFocusForm,
  setCurrentScreenshot,
  enableScreenRecorderMode,
  disableScreenRecorderMode,
  setEditReportUuid,
  enableLayoutCompareMode,
  disableLayoutCompareMode,
  setReportsBoardVisible,
  setLoading,
  resetReportsState,
} = reporterSlice.actions;

export const reporterReducer = reporterSlice.reducer;
