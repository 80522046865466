import {createApi} from '@reduxjs/toolkit/dist/query/react';
import {baseQuery} from '../common/api';
import {ProjectPageInterface} from '../interfaces/project-page.interface';

function transformResponse(data: {[key: string]: any}): ProjectPageInterface {
  return {
    uuid: data.uuid,
    projectUuid: data.project_uuid,
    title: data.title,
    url: data.url,
    createdAt: data.created_at,
    updatedAt: data.updated_at,
  };
}

export const favoritePagesApi = createApi({
  reducerPath: 'favoritePagesApi',
  baseQuery,
  endpoints: (builder) => ({
    findFavoritePages: builder.query<
      {
        data: ProjectPageInterface[];
      },
      string
    >({
      query: (projectUuid) => `projects/${projectUuid}/favorite`,
      transformResponse: (response: any) => {
        return {
          ...response,
          data: response.data.map(transformResponse),
        };
      },
    }),

    createFavoritePage: builder.mutation<
      {
        data: ProjectPageInterface;
      },
      ProjectPageInterface & Pick<ProjectPageInterface, 'projectUuid'>
    >({
      query: ({projectUuid, ...body}) => ({
        url: `projects/${projectUuid}/favorite`,
        method: 'POST',
        body,
      }),
      async onQueryStarted({projectUuid, ...body}, {dispatch, queryFulfilled}) {
        const patchResult = dispatch(
          favoritePagesApi.util.updateQueryData('findFavoritePages', projectUuid, (draft) => {
            draft.data.unshift(body);
          })
        );
        try {
          const {data} = await queryFulfilled;
          patchResult.undo();
          dispatch(
            favoritePagesApi.util.updateQueryData('findFavoritePages', projectUuid, (draft) => {
              draft.data.unshift(data.data);
            })
          );
        } catch (e) {
          patchResult.undo();
        }
      },
    }),

    deleteFavoritePage: builder.mutation<
      void,
      {
        projectUuid: string;
        uuid: string;
      }
    >({
      query: ({projectUuid, uuid}) => ({
        url: `projects/${projectUuid}/favorite/${uuid}`,
        method: 'DELETE',
      }),
      async onQueryStarted({projectUuid, uuid}, {dispatch, queryFulfilled}) {
        const patchResult = dispatch(
          favoritePagesApi.util.updateQueryData('findFavoritePages', projectUuid, (draft) => {
            draft.data = draft.data.filter((page) => page.uuid !== uuid);
          })
        );
        queryFulfilled.catch(() => patchResult.undo());
      },
    }),
  }),
});

export const {useFindFavoritePagesQuery, useCreateFavoritePageMutation, useDeleteFavoritePageMutation} = favoritePagesApi;
