import './styles/main.scss';
import {useAppSelector} from './hooks';
import './interfaces/electron.interface';
import {BrowserRouter, Routes, Route, Navigate, useLocation} from "react-router-dom";
import {HomePage} from "./screens/homepage/HomePage";
import {Auth} from "./screens/auth/Auth";
import {Reporter} from "./screens/reporter/Reporter";
import {RequireAuth} from "./components/RequireAuth";
import './App.scss'

export function App() {
  const loggedIn = useAppSelector((state) => state.auth.loggedIn);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/projects"/>}/>
        <Route path="/auth" element={loggedIn ? <Navigate to="/projects"/> : <Auth/>}/>
        <Route path="/projects" element={(
          <RequireAuth>
            <HomePage/>
          </RequireAuth>
        )}/>
        <Route path="/projects/:projectUuid" element={(
          <RequireAuth>
            <Reporter/>
          </RequireAuth>
        )}/>
        <Route path="/projects/:projectUuid/:reportUuid" element={(
          <RequireAuth>
            <Reporter/>
          </RequireAuth>
        )}/>
      </Routes>
    </BrowserRouter>
  );
}
