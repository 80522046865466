import {useEffect} from 'react';
import {Button, Dimmer, Dropdown} from 'semantic-ui-react';
import {useAppDispatch, useAppSelector} from '../hooks';
import {unsetUser} from '../slices/auth.slice';
import {dropDownMenuReposition} from '../common/helpers';
import {useLogoutMutation} from '../apis/auth.api';
import {LoaderCircle} from '../screens/homepage/components/LoaderCircle';
import {Abbr} from './Abbr';
import {Link} from "react-router-dom";
import {FeedbackModal} from "./FeedbackModal";

export function User() {
  const auth = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [logoutUser, {isLoading, isSuccess}] = useLogoutMutation();

  useEffect(() => {
    if (isSuccess) {
      dispatch(unsetUser());
    }
  }, [isSuccess]);

  return (
    <>
      <Dropdown
        trigger={
          <Button className="button--darken">
            <Abbr name={auth.name || auth.username || 'Account'} />
            <i className="icon icon-angle" />
          </Button>
        }
        icon={false}
        direction="left"
        onOpen={dropDownMenuReposition}
      >
        <Dropdown.Menu className="inverse" style={{minWidth: 200}}>
          <Dimmer active={isLoading} className="dimmer--dark">
            <LoaderCircle />
          </Dimmer>
          <Dropdown.Item style={{pointerEvents: 'none'}}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
              {auth.loggedIn && (
                <>
                  <span className="mb-1">{auth.name || auth.username || 'Account'}</span>
                  {auth.name !== auth.username && (
                    <span className="font-sm text-secondary">{auth.name && auth.username}</span>
                  )}
                </>
              )}
            </div>
          </Dropdown.Item>
          <Dropdown.Divider />
          <FeedbackModal>
            <Dropdown.Item>We need your feedback</Dropdown.Item>
          </FeedbackModal>
          <Dropdown.Item href="https://reporter.tools/contacts" target="_blank">Contacts</Dropdown.Item>
          {auth.loggedIn ? (
            <>
              <Dropdown.Divider />
              <Dropdown.Item
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  logoutUser();
                }}
              >
                Logout
              </Dropdown.Item>
            </>
          ) : (
            <></>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
