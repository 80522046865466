import {createApi} from '@reduxjs/toolkit/dist/query/react';
import {baseQuery} from '../common/api';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery,
  endpoints: (builder) => ({
    authByEmail: builder.mutation<{
        token: string;
        name: string;
        user_uuid: string;
    },
      {
        email: string;
        password: string;
      }
    >({
      query: (body) => ({
        url: `auth/email`,
        method: 'POST',
        body,
      }),
    }),

    createAccount: builder.mutation<
      object,
      {
        email: string;
        password: string;
        password_confirm: string;
        app_fcm_token?: string;
      }
    >({
      query: (body) => ({
        url: `account/create`,
        method: 'POST',
        body,
      }),
    }),

    resetPassword: builder.mutation<
      void,
      {
        email: string;
      }
    >({
      query: (body) => ({
        url: `account/password/request-reset`,
        method: 'POST',
        body,
      }),
    }),

    resendVerificationLink: builder.mutation<
      void,
      {
        email: string;
      }
    >({
      query: (body) => ({
        url: `account/resend-verification-link`,
        method: 'POST',
        body,
      }),
    }),

    logout: builder.mutation<void, void>({
      query: () => ({
        url: `auth/logout`,
        method: 'POST',
      }),
    }),

    feedback: builder.mutation<
      void,
      {
        email: string;
        name: string;
        text: string;
        source: string;
      }
      >({
      query: (body) => ({
        url: `feedback`,
        method: 'POST',
        body,
      }),
      async onQueryStarted(_patch, {queryFulfilled}) {
        try {
          await queryFulfilled;
        } catch (e) {}
      },
    }),
  }),
});

export const {useAuthByEmailMutation, useCreateAccountMutation, useResetPasswordMutation, useLogoutMutation, useResendVerificationLinkMutation, useFeedbackMutation} = authApi;
