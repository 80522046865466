import {Button, Card, Dimmer, Form} from 'semantic-ui-react';
import {useEffect, useState} from 'react';
import {useAuthByEmailMutation} from '../../apis/auth.api';
import {useAppDispatch} from '../../hooks';
import {setUser} from '../../slices/auth.slice';
import {LoaderDots} from '../homepage/components/LoaderDots';
import {openReporterPage} from '../../common/browser';
import {ForgotPasswordModal} from './ForgotPasswordModal';
import {useNavigate} from "react-router-dom";

export function Login({fcmToken, redirectAfterLogin, onSwitch}: {fcmToken: string; redirectAfterLogin: string; onSwitch: () => void}) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [auth, {error, data: authData, isError, isLoading: authIsLoading}] = useAuthByEmailMutation();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  function authSubmit() {
    if (email && password) {
      auth({
        email,
        password,
      });
    }
  }

  useEffect(() => {
    if (authData?.token) {
      dispatch(
        setUser({
          userUuid: authData.user_uuid,
          username: email,
          name: authData.name,
          token: authData.token,
        })
      );
      navigate(redirectAfterLogin, {replace: true});
    }
  }, [authData]);

  return (
    <div className="login-page">
      <Card>
        <Card.Content>
          <Dimmer active={authIsLoading || loading}>
            <LoaderDots />
          </Dimmer>
          <i className="icon icon-logo icon--xl" />
          <h3>Sign in</h3>
          <p className="text-secondary mb-4">
            New user?{' '}
            <a className="link" onClick={() => onSwitch()}>
              Create an account
            </a>
          </p>
          <Button.Group>
            <a className="button button--social" href={`${process.env.REACT_APP_ACCOUNT_APP_URL}/auth/google?redirect=${window.location.href.split('?')[0]}`}>
              <i className="icon icon-google icon--sm mr-1" /> Google
            </a>
            <a className="button button--social" href={`${process.env.REACT_APP_ACCOUNT_APP_URL}/auth/github?redirect=${window.location.href.split('?')[0]}`}>
              <i className="icon icon-github icon--sm mr-1" /> GitHub
            </a>
          </Button.Group>
          <p className="or">or</p>
          <Form>
            <Form.Input
              className="input--md"
              value={email}
              autoFocus
              onChange={(_e, data) => setEmail(data.value.trim())}
              placeholder="Email"
              onKeyUp={(e) => e.code === 'Enter' && authSubmit()}
              error={isError ? ((error as any)?.data?.errors ? (error as any)?.data?.errors?.email : (error as any)?.data?.message) : false}
            />
            <Form.Input
              type="password"
              value={password}
              className="input--md"
              onChange={(_e, data) => setPassword(data.value.trim())}
              placeholder="Password"
              onKeyUp={(e) => e.code === 'Enter' && authSubmit()}
              error={isError && (error as any)?.data?.errors?.password}
            />
            <p className="mt-3 mb-3">
              <ForgotPasswordModal>
                <a className="link">Forgot password?</a>
              </ForgotPasswordModal>
            </p>
          </Form>
          <Button
            fluid
            content="Log in"
            disabled={!email.trim() || !password.trim()}
            onClick={() => authSubmit()}
            primary
          />
        </Card.Content>
      </Card>
    </div>
  );
}
