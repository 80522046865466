import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {browserBringTop, browserSendBack} from '../common/browser';

export type WindowStateType = 'minimized' | 'maximized' | 'unmaximized' | 'restored';

export interface GlobalStateInterface {
  windowState: WindowStateType;
  globalErrorHandlerEnabled: boolean;
  sync: boolean;
  syncError: string;
}

export const globalSlice = createSlice({
  name: 'global',
  initialState: <GlobalStateInterface>{
    windowState: 'maximized',
    globalErrorHandlerEnabled: true,
    sync: false,
    syncError: '',
  },
  reducers: {
    setWindowState(state: GlobalStateInterface, action: PayloadAction<WindowStateType>) {
      state.windowState = action.payload;
    },
    enableGlobalErrorHandler: (draft: GlobalStateInterface) => {
      draft.globalErrorHandlerEnabled = true;
    },
    disableGlobalErrorHandler: (draft: GlobalStateInterface) => {
      draft.globalErrorHandlerEnabled = false;
    },
    setSync: (draft: GlobalStateInterface, action: PayloadAction<boolean>) => {
      draft.sync = action.payload;
    },
    setSyncError: (draft: GlobalStateInterface, action: PayloadAction<string>) => {
      if (draft.syncError !== action.payload) {
        draft.syncError = action.payload;
        action.payload ? browserSendBack() : browserBringTop();
      }
    },
  },
});

export const {setWindowState, setSync, setSyncError, enableGlobalErrorHandler, disableGlobalErrorHandler} = globalSlice.actions;

export const globalReducer = globalSlice.reducer;
