import {Image, Modal, Transition} from 'semantic-ui-react';
import {useEffect, useRef, useState} from 'react';
import {disableFreezeMode, enableFreezeMode} from '../../slices/reporter.slice';
import {useAppDispatch} from '../../hooks';

export function Gallery({
  activeIndex,
  isOpen,
  onClose,
  items,
}: {
  activeIndex: number;
  isOpen?: boolean;
  onClose?: () => void;
  items: string[];
}) {
  const firstRender = useRef(true);
  const [current, setCurrent] = useState(items[activeIndex]);
  const [open, setOpen] = useState(isOpen);
  const [isVideo, setIsVideo] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setIsVideo(current?.toString().startsWith('data:video') || current?.toString().endsWith('.webm'));
  }, [current]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else if (isOpen) {
      dispatch(enableFreezeMode());
    } else {
      onClose?.call(this);
      setTimeout(() => {
        dispatch(disableFreezeMode());
      }, 100);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => setOpen(true), 50);
    } else {
      setOpen(false);
    }
  }, [isOpen]);

  useEffect(() => {
    setCurrent(items[activeIndex]);
  }, [activeIndex, items]);

  return (
    <Transition visible={open} animation="scale">
      <Modal className="gallery" open={open} closeOnEscape closeOnDimmerClick onClose={() => onClose?.call(this)}>
        <>
          {isVideo ? <video src={current} autoPlay loop /> : <Image src={items[activeIndex]} />}
        </>
      </Modal>
    </Transition>
  );
}
