import {useEffect, useState} from 'react';
import {isNumber} from 'lodash';
import {Card} from 'semantic-ui-react';
import {BrowserStepInterface} from '../../../interfaces/browser-step.interface';
import {formatDateTime} from '../../../common/helpers';
import {browserNavigate} from '../../../common/browser';

export function Steps({steps, reverse}: {steps: BrowserStepInterface[]; reverse?: boolean}) {
  const [sorted, setSorted] = useState(steps);

  useEffect(() => {
    if (reverse) {
      setSorted([...steps].reverse());
    } else {
      setSorted([...steps]);
    }
  }, [steps, reverse]);

  const TruncatedText = function ({children, className, onClick}: any) {
    return (
      <span
        className={`text-secondary ${className || ''}`}
        style={{whiteSpace: 'nowrap', flex: '1 1', overflow: 'hidden', textOverflow: 'ellipsis'}}
        onClick={onClick}
      >
        {children}
      </span>
    );
  };

  return (
    <div className="card-list card-list--vertical card-list--striped">
      {sorted.map((step, i) => (
        <Card key={i}>
          <Card.Content>
            <div className="title mb-2">
              {step.event}
              {step.details.title && ' on'}
              {step.details.title && <TruncatedText>"{step.details.title}"</TruncatedText>}
              {!step.details.title && step.details.x && step.details.y && ' on'}
              {!step.details.title && step.details.x && step.details.y && (
                <span className="text-secondary">
                  {step.details.x}x{step.details.y}
                </span>
              )}
              {isNumber(step.details.top) && ' to'}
              {isNumber(step.details.top) && (
                <span className="text-secondary">{step.details.top ? `${step.details.top}px from top` : 'Top'}</span>
              )}

              {step.details.url && ' to'}
              {step.details.url && (
                <TruncatedText>
                  <a href={step.details.url} target="_blank" className="link">{step.details.url}
                  </a>
                </TruncatedText>
              )}
            </div>
            {step.details.value && (
              <div className="title mb-2">
                value:<TruncatedText>{step.details.value}</TruncatedText>
              </div>
            )}
            <Card.Meta>{formatDateTime(new Date(step.datetime || new Date()), true)}</Card.Meta>
          </Card.Content>
        </Card>
      ))}
    </div>
  );
}
