import PerfectScrollbar from 'react-perfect-scrollbar';
import {useEffect} from 'react';
import {Header} from './components/Header';
import {BaseLayout} from '../../components/BaseLayout';
import {ProjectList} from './components/ProjectList';
import {SidebarLeft} from './components/SidebarLeft';
import {useFindProjectsQuery} from '../../apis/projects.api';
import {LoaderLogo} from './components/LoaderLogo';
import {usePlanQuery} from "../../apis/subscription.api";
import {useAppSelector} from "../../hooks";
import {UserPlanInterface} from "../../interfaces/user-plan-interface";
import {useGetTeamQuery} from "../../apis/teams.api";
import {Divider} from "semantic-ui-react";

export function HomePage() {
  const {data, isError, isLoading: isProjectsLoading, refetch} = useFindProjectsQuery();
  const userUuid = useAppSelector((state) => state.auth.userUuid);
  const {data: plan, isLoading: isPlanLoading, refetch: refetchPlan} = usePlanQuery({userUuid});
  const activeTeamUuid = useAppSelector((state) => state.projects.team);
  const {data: activeTeam, isLoading: isTeamLoading} = useGetTeamQuery(activeTeamUuid as string, {
    skip: !activeTeamUuid,
  });
  const section = useAppSelector((state) => state.projects.section);
  const isLoading = isProjectsLoading || isTeamLoading || isPlanLoading;
  let timeout;

  useEffect(() => {
    section === 'recent' && refetch();
  }, [section]);

  useEffect(() => {
    if (isError) {
      timeout && clearTimeout(timeout);
      timeout = setTimeout(() => refetch(), 5000);
    }
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [isError]);

  return (
    <BaseLayout
      headerContents={<Header />}
      sidebarLeftContents={<SidebarLeft plan={plan as UserPlanInterface} />}
      mainContents={
        <PerfectScrollbar>
          {(isLoading || isError) && <LoaderLogo absolute />}
          <div style={{padding: 40}}>
            <h1 className="mb-4 mt-0">
              {activeTeamUuid && activeTeam?.data && activeTeam?.data?.title}
              {section === 'recent' && 'Recent projects'}
              {section === 'personal' && 'Personal projects'}
              {section === 'favorites' && 'Favorite projects'}
              {section === 'archive' && 'Deleted projects'}
            </h1>
            <Divider className="mt-4 mb-4" />
            {!isError && !isLoading && <ProjectList projects={data?.data || []} />}
          </div>
        </PerfectScrollbar>
      }
    />
  );
}
