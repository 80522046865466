import {UserPlanInterface} from '../interfaces/user-plan-interface';
import {TeamPlanInterface} from '../interfaces/team-plan-interface';
import {SubscriptionStatusEnum} from '../interfaces/subscription-status.enum';

export enum SubscriptionTypeEnum {
  PERSONAL_FREE = 'personal-free',
  PERSONAL_PRO = 'personal-pro',
  TEAM_FREE = 'team-free',
  TEAM_PRO = 'team-pro',
}

export const plansLabel: any = {
  [SubscriptionTypeEnum.PERSONAL_FREE]: 'Personal Free',
  [SubscriptionTypeEnum.PERSONAL_PRO]: 'Personal Pro',
  [SubscriptionTypeEnum.TEAM_FREE]: 'Team Free',
  [SubscriptionTypeEnum.TEAM_PRO]: 'Team Pro',
};

export const subscriptionStatusLabel: any = {
  [SubscriptionStatusEnum.STATUS_ACTIVE]: 'Active',
  [SubscriptionStatusEnum.STATUS_TRIALING]: 'Trialing',
  [SubscriptionStatusEnum.STATUS_PAUSED]: 'Paused',
  [SubscriptionStatusEnum.STATUS_PAST_DUE]: 'Past due',
  [SubscriptionStatusEnum.STATUS_DELETED]: 'Cancelled',
};

export function isProjectsLimitReached(plan?: UserPlanInterface | TeamPlanInterface): boolean {
  return Boolean(plan && plan.projectsLimit && plan.projectsLimitUsed >= plan.projectsLimit);
}
