import {Abbr} from './Abbr';
import {ReportInterface} from '../interfaces/report.interface';
import {useAppSelector} from '../hooks';
import {CSSProperties} from 'react';

export function ReportMembers({report, style, className}: {report: ReportInterface; style?: CSSProperties, className?: string}) {
  const projectMembers = useAppSelector((state) => state.projects.members);

  return (
    <div style={{...(style || {}), display: 'flex', gap: 5, flexWrap: 'wrap'}} className={className}>
      {report.memberUuids.map((userUuid, i) => {
        const member = projectMembers.find((item) => item.userUuid === userUuid);
        return member ? (
          <Abbr
            key={`report-member-${i}`}
            name={member.userName || member.userEmail || member.invitationEmail || 'Unknown'}
            title={member.userName || member.userEmail || member.invitationEmail || 'Unknown'}
          />
        ) : (
          ''
        );
      })}
    </div>
  );
}
