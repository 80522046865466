import {useEffect, useRef, useState} from 'react';
import {resetReport, resetReportsState, setLoading, setUrl} from '../../slices/reporter.slice';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {ProjectInterface} from '../../interfaces/project.interface';
import {BaseLayout} from '../../components/BaseLayout';
import {getLayouts} from '../../thunks/layout-compare.thunks';
import {reset as resetLayoutCompare} from '../../slices/layout-compare.slice';
import {ReportsBoard} from './components/ReportsBoard';
import {useFindReportsQuery} from '../../apis/reports.api';
import {setPageUrlFilter, setReports} from '../../slices/reports.slice';
import {EventBus} from '../../common/event-bus';
import {browserNavigate, browserShow} from '../../common/browser';
import {useFindProjectPagesQuery} from '../../apis/project-pages.api';
import {useFindFavoritePagesQuery} from '../../apis/favorite-pages.api';
import {setProjectPages} from '../../slices/project-pages.slice';
import {setFavoritePages} from '../../slices/favorite-pages.slice';
import {invokeInMain} from '../../common/helpers';
import {useGetProjectQuery, useUpdateProjectMutation} from '../../apis/projects.api';
import {SidebarLeft} from "./components/SidebarLeft";
import {closeProject, openProject, setActiveProject, setProjectMembers} from "../../slices/projects.slice";
import {useParams} from "react-router-dom";
import {Header} from "./components/Header";
import {LoaderLogo} from "../homepage/components/LoaderLogo";
import {Dimmer, Transition} from "semantic-ui-react";
import {useFindProjectMembersQuery} from "../../apis/project-members.api";
import {ProjectMemberInterface} from "../../interfaces/project-member.interface";

export function Reporter() {
  const {projectUuid} = useParams();
  const dispatch = useAppDispatch();
  const [waiting, setWaiting] = useState(true);
  const auth = useAppSelector((state) => state.auth);
  const projectQuery = useGetProjectQuery(projectUuid);
  const project = projectQuery.data?.data;
  const reportsQuery = useFindReportsQuery(project?.uuid, {skip: !project});
  const projectPagesQuery = useFindProjectPagesQuery(project?.uuid, {skip: !project});
  const favoritePagesQuery = useFindFavoritePagesQuery(project?.uuid, {skip: !project});
  const projectMembersQuery = useFindProjectMembersQuery(project?.uuid, {skip: !project});

  useEffect(() => {
    dispatch(openProject(projectUuid));

    return () => {
      dispatch(closeProject(projectUuid));
      dispatch(resetReportsState());
    }
  }, []);

  useEffect(() => {
    dispatch(setLoading(true));
  }, [project?.uuid]);

  useEffect(() => {
    projectQuery.refetch();
    reportsQuery.refetch();
    projectPagesQuery.refetch();
    favoritePagesQuery.refetch();
    projectMembersQuery.refetch();
  }, [project?.uuid]);

  useEffect(() => {
    setWaiting(!(projectQuery.isSuccess && reportsQuery.isSuccess && projectPagesQuery.isSuccess && favoritePagesQuery.isSuccess && projectMembersQuery.isSuccess));
  }, [projectQuery.isSuccess, reportsQuery.isSuccess, projectPagesQuery.isSuccess, favoritePagesQuery.isSuccess, projectMembersQuery.isSuccess]);

  useEffect(() => {
    projectQuery?.data?.data && dispatch(setActiveProject(projectQuery?.data?.data));
  }, [projectQuery.isSuccess])

  useEffect(() => {
    reportsQuery?.data?.data && dispatch(setReports(reportsQuery?.data?.data));
    projectPagesQuery?.data?.data && dispatch(setProjectPages(projectPagesQuery?.data?.data));
    favoritePagesQuery?.data?.data && dispatch(setFavoritePages(favoritePagesQuery?.data.data));
    projectMembersQuery?.data?.data &&
    dispatch(
      setProjectMembers([
        ...projectMembersQuery?.data.data,
        {
          userUuid: auth.userUuid as string,
          userName: auth.name as string,
          userEmail: auth.username as string,
        } as ProjectMemberInterface,
      ])
    );
  }, [reportsQuery, projectPagesQuery, favoritePagesQuery, projectMembersQuery]);

  useEffect(() => {
    dispatch(resetReport({showBrowser: false}));
    dispatch(resetLayoutCompare());
    dispatch(getLayouts(project?.uuid));
  }, []);

  useEffect(() => {
    if (project?.url) {
      // browserNavigate(project.url);
      // dispatch(setUrl(project.url));
      // dispatch(setPageUrlFilter(project.url));
    }
  }, [project?.url]);
  return (
    <BaseLayout
      className="screen--reporter"
      headerContents={<Header project={project} waiting={waiting}/>}
      sidebarLeftContents={waiting ? undefined : <SidebarLeft/>}
      mainContents={waiting ? <Dimmer active className="dimmer--transparent"><LoaderLogo /></Dimmer> : <ReportsBoard/>}
      // sidebarRightContents={reportsBoardVisible ? undefined : waiting ? <></> : <SidebarRight />}
    />
  );
}
