import {createApi} from '@reduxjs/toolkit/dist/query/react';
import {isUndefined, omitBy} from 'lodash';
import {ProjectInterface} from '../interfaces/project.interface';
import {NewProjectInterface} from '../interfaces/new-project.interface';
import {baseQuery} from '../common/api';

export function transformProject(data: {[key: string]: any}): ProjectInterface {
  return {
    uuid: data.uuid,
    name: data.name,
    url: data.url,
    preview: data.preview,
    favorite: data.favorite,
    createdAt: data.created_at,
    updatedAt: data.updated_at,
    viewedAt: data.viewed_at,
    status: data.status,
    userUuid: data.user_uuid,
    userStatus: data.user_status,
    teamUuid: data.team_uuid,
    teamName: data.team_name,
    shared: data.shared,
    membersCount: data.members_count,
    memberUuids: data.member_uuids,
  };
}

function transformRequestBody(body: Partial<ProjectInterface>): object {
  return omitBy(
    {
      name: body.name,
      url: body.url,
      favorite: body.favorite,
      viewed_at: body.viewedAt,
      preview: body.preview,
      status: body.status,
      team_uuid: body.teamUuid,
      shared: body.shared,
      member_uuids: body.memberUuids,
    },
    isUndefined
  );
}

export const projectsApi = createApi({
  reducerPath: 'projectsApi',
  baseQuery,
  tagTypes: ['Project'],
  endpoints: (builder) => ({
    /**
     * Find project
     */
    findProjects: builder.query<
      {
        data: ProjectInterface[];
      },
      void
    >({
      query: () => `projects`,
      providesTags: ['Project'],
      transformResponse: (response: any) => {
        return {
          ...response,
          data: response.data.map(transformProject),
        };
      },
    }),

    /**
     * Get project
     */
    getProject: builder.query<
      {
        data: ProjectInterface;
      },
      string
      >({
      query: (uuid) => `projects/${uuid}`,
      transformResponse: (response: any) => {
        return {
          ...response,
          data: transformProject(response.data),
        };
      },
    }),


    /**
     * Create new project
     */
    createProject: builder.mutation<
      {
        data: ProjectInterface;
      },
      NewProjectInterface
    >({
      query: (body) => ({
        url: `projects`,
        method: 'POST',
        body: transformRequestBody(body),
      }),
      async onQueryStarted(_patch, {dispatch, queryFulfilled}) {
        try {
          const {data} = await queryFulfilled;
          dispatch(
            projectsApi.util.updateQueryData('findProjects', undefined, (draft) => {
              draft.data.unshift(transformProject(data.data));
            })
          );
        } catch (e) {
          console.log(e);
        }
      },
    }),

    /**
     * Update existing project
     */
    updateProject: builder.mutation<
      {
        data: ProjectInterface;
      },
      Partial<ProjectInterface>
    >({
      query: ({uuid, ...patch}) => ({
        url: `projects/${uuid}`,
        method: 'PATCH',
        body: transformRequestBody(patch),
      }),
      async onQueryStarted({uuid, ...patch}, {dispatch, queryFulfilled}) {
        const patchResult = dispatch(
          projectsApi.util.updateQueryData('findProjects', undefined, (draft) => {
            const project = draft.data.find((item) => item.uuid === uuid);
            Object.assign(project, patch);
          })
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),

    /**
     * Add project to favorites
     */
    addToFavorites: builder.mutation<void, string>({
      query: (uuid) => ({
        url: `favorite`,
        method: 'POST',
        body: {
          entity_name: 'Project',
          entity_uuid: uuid,
        },
      }),
      async onQueryStarted(uuid, {dispatch, queryFulfilled}) {
        const patchResult1 = dispatch(
          projectsApi.util.updateQueryData('getProject', uuid, (draft) => {
            draft.data.favorite = true;
          })
        );
        const patchResult2 = dispatch(
          projectsApi.util.updateQueryData('findProjects', undefined, (draft) => {
            const project = draft.data.find((item) => item.uuid === uuid);
            if (project) {
              project.favorite = true;
            }
          })
        );
        queryFulfilled.catch(() => {
          patchResult1.undo();
          patchResult2.undo();
        });
      },
    }),

    /**
     * Remove project from favorites
     */
    removeFromFavorites: builder.mutation<void, string>({
      query: (uuid) => ({
        url: `favorite`,
        method: 'DELETE',
        body: {
          entity_name: 'Project',
          entity_uuid: uuid,
        },
      }),
      async onQueryStarted(uuid, {dispatch, queryFulfilled}) {
        const patchResult1 = dispatch(
          projectsApi.util.updateQueryData('getProject', uuid, (draft) => {
            draft.data.favorite = false;
          })
        );
        const patchResult2 = dispatch(
          projectsApi.util.updateQueryData('findProjects', undefined, (draft) => {
            const project = draft.data.find((item) => item.uuid === uuid);
            if (project) {
              project.favorite = false;
            }
          })
        );
        queryFulfilled.catch(() => {
          patchResult1.undo();
          patchResult2.undo();
        });
      },
    }),

    /**
     * Delete project
     */
    deleteProject: builder.mutation<void, string>({
      query: (uuid) => ({
        url: `projects/${uuid}`,
        method: 'DELETE',
      }),
      async onQueryStarted(uuid, {dispatch, queryFulfilled}) {
        const patchResult = dispatch(
          projectsApi.util.updateQueryData('findProjects', undefined, (draft) => {
            draft.data = draft.data.filter((item) => item.uuid !== uuid);
          })
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),
  }),
});

export const {
  useFindProjectsQuery,
  useGetProjectQuery,
  useCreateProjectMutation,
  useUpdateProjectMutation,
  useAddToFavoritesMutation,
  useRemoveFromFavoritesMutation,
  useDeleteProjectMutation,
} = projectsApi;
