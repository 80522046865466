/**
 * Wrapper on ipcRenderer for interprocess communication
 */
export class EventBus {
  static on(event: string, handler: (...args: any) => void | (() => void)) {
    // return window.electron.ipcRenderer.on(event, handler);
    return undefined;
  }

  static once(event: string, handler: () => void) {
    // window.electron.ipcRenderer.once(event, handler);
  }

  static sendToMain(event: string, payload: object = {}, sync = false): void {
    // console.debug(`send to main process: ${event} ${JSON.stringify(payload)}`);
    // window.electron.ipcRenderer.send(event, payload, sync);
  }
}
