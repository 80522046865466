import {useEffect, useState} from 'react';
import {BaseLayout} from '../../components/BaseLayout';
import {Header} from './Header';
import {Login} from './Login';
import {EventBus} from '../../common/event-bus';
import {setUser} from '../../slices/auth.slice';
import {useAppDispatch} from '../../hooks';
import {getFcmToken} from '../../common/helpers';
import {SignUp} from './SignUp';
import {LoaderLogo} from '../homepage/components/LoaderLogo';
import {useLocation, useNavigate} from "react-router-dom";

export function Auth() {
  const [isSignUp, setIsSignUp] = useState(false);
  const [fcmToken, setFcmToken] = useState('');
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const redirectAfterLogin = (location.state as any)?.from || '/';

  // try to extract auth data from cookies
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const authData = urlSearchParams.get('auth');
    if (authData) {
      try {
        let data = JSON.parse(window.atob(authData));
        if (data.email && data.token) {
          dispatch(
            setUser({
              userUuid: data.user_uuid,
              username: data.email,
              name: data.name,
              token: data.token,
            })
          );
          navigate(redirectAfterLogin, {replace: true});
        }
      } catch (e) {
        console.error('Invalid auth data');
      }
    }
  }, []);

  useEffect(() => {
    // EventBus.on('notification', ({event, ...data}) => {
    //   if (event === 'auth') {
    //     dispatch(
    //       setUser({
    //         username: data.email,
    //         name: data.name,
    //         token: data.token,
    //       })
    //     );
    //   }
    // });
  }, []);

  return (
    <BaseLayout
      headerContents={<Header/>}
      mainContents={
        isSignUp ? (
          <SignUp fcmToken={fcmToken} onSwitch={() => setIsSignUp(false)}/>
        ) : (
          <Login fcmToken={fcmToken} redirectAfterLogin={redirectAfterLogin} onSwitch={() => setIsSignUp(true)}/>
        )
      }
    />
  );
}
