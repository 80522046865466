import {createApi} from '@reduxjs/toolkit/dist/query/react';
import {baseQuery} from '../common/api';
import {ProjectPageInterface} from '../interfaces/project-page.interface';

function transformResponse(data: {[key: string]: any}): ProjectPageInterface {
  return {
    uuid: data.uuid,
    projectUuid: data.project_uuid,
    title: data.title,
    url: data.url,
    createdAt: data.created_at,
    updatedAt: data.updated_at,
  };
}

export const projectPagesApi = createApi({
  reducerPath: 'projectPagesApi',
  baseQuery,
  endpoints: (builder) => ({
    findProjectPages: builder.query<
      {
        data: ProjectPageInterface[];
      },
      string
    >({
      query: (projectUuid) => `projects/${projectUuid}/pages`,
      transformResponse: (response: any) => {
        return {
          ...response,
          data: response.data.map(transformResponse),
        };
      },
    }),

    createProjectPage: builder.mutation<
      {
        data: ProjectPageInterface;
      },
      ProjectPageInterface & Pick<ProjectPageInterface, 'projectUuid'>
    >({
      query: ({projectUuid, ...body}) => ({
        url: `projects/${projectUuid}/pages`,
        method: 'POST',
        body,
      }),
      async onQueryStarted({projectUuid, ...body}, {dispatch, queryFulfilled}) {
        const patchResult = dispatch(
          projectPagesApi.util.updateQueryData('findProjectPages', projectUuid, (draft) => {
            draft.data.push(body);
          })
        );
        try {
          const {data} = await queryFulfilled;
          patchResult.undo();
          dispatch(
            projectPagesApi.util.updateQueryData('findProjectPages', projectUuid, (draft) => {
              draft.data.push(data.data);
            })
          );
        } catch (e) {
          patchResult.undo();
        }
      },
    }),

    deleteProjectPage: builder.mutation<
      void,
      {
        projectUuid: string;
        pageUuid: string;
      }
    >({
      query: ({projectUuid, pageUuid}) => ({
        url: `projects/${projectUuid}/pages/${pageUuid}`,
        method: 'DELETE',
      }),
      async onQueryStarted({projectUuid, pageUuid}, {dispatch, queryFulfilled}) {
        const patchResult = dispatch(
          projectPagesApi.util.updateQueryData('findProjectPages', projectUuid, (draft) => {
            draft.data = draft.data.filter((page) => page.uuid !== pageUuid);
          })
        );
        queryFulfilled.catch(() => patchResult.undo());
      },
    }),
  }),
});

export const {useFindProjectPagesQuery, useCreateProjectPageMutation, useDeleteProjectPageMutation} = projectPagesApi;
