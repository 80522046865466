import {Card, Divider, Menu} from 'semantic-ui-react';
import {useAppDispatch, useAppSelector} from '../../../hooks';
import {setSection, setTeam} from '../../../slices/projects.slice';
import {useFindTeamsQuery} from "../../../apis/teams.api";
import {useEffect} from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { TruncatedText } from '../../../components/TruncatedText';
import {UserPlanInterface} from "../../../interfaces/user-plan-interface";
import {SubscriptionTypeEnum} from "../../../common/subscriptions";
import './SidebarLeft.scss';

export function SidebarLeft({plan}: {plan: UserPlanInterface}) {
  const section = useAppSelector((state) => state.projects.section);
  const activeTeam = useAppSelector((state) => state.projects.team);
  const dispatch = useAppDispatch();
  const teamsQuery = useFindTeamsQuery();

  useEffect(() => {
    teamsQuery.refetch();
  }, []);

  return (
    <PerfectScrollbar>
      <header className="sidebar__header">Projects</header>
      <Menu vertical fluid className="projects-menu">
        <Menu.Item active={section === 'recent'} onClick={() => dispatch(setSection('recent'))}>
          <i className="icon icon-clock icon--dark icon--sm"/> Recents
        </Menu.Item>
        <Menu.Item active={section === 'personal'} onClick={() => dispatch(setSection('personal'))}>
          <i className="icon icon-inbox icon--dark icon--sm"/> Personal projects
          {plan?.plan === SubscriptionTypeEnum.PERSONAL_PRO && <label className="badge badge--primary icon-personal-pro">pro</label>}
        </Menu.Item>
        <Menu.Item active={section === 'favorites'} onClick={() => dispatch(setSection('favorites'))}>
          <i className="icon icon-star icon--dark icon--sm"
             style={{WebkitMaskSize: 16, WebkitMaskPositionY: 1}}/> Favorites
        </Menu.Item>
        <Menu.Item active={section === 'archive'} onClick={() => dispatch(setSection('archive'))}>
          <i className="icon icon-archive icon--dark icon--sm"/> Deleted
        </Menu.Item>
        <Divider/>
        <Menu.Header>Teams</Menu.Header>
        {teamsQuery.data?.data?.map((team, i) => (
          <Menu.Item key={`team-${i}`} onClick={() => dispatch(setTeam(team.uuid))} active={activeTeam === team.uuid}>
            <i className="icon icon-people icon--dark icon--sm"/>
            <TruncatedText>{team.title}</TruncatedText>
            {team.isPro && <label className="badge badge--primary icon-team-pro">pro</label>}
          </Menu.Item>
        ))}
      </Menu>
    </PerfectScrollbar>
  );
}
