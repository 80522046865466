import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {without} from 'lodash';
import {ProjectInterface} from '../interfaces/project.interface';
import {projectsApi, transformProject} from '../apis/projects.api';
import {ProjectMemberInterface} from "../interfaces/project-member.interface";

export interface ProjectsStateInterface {
  search: string;
  section: 'recent' | 'personal' | 'favorites' | 'archive';
  team?: string;
  all: ProjectInterface[];
  activeProject?: ProjectInterface;
  active: string | null;
  opened: string[];
  members: ProjectMemberInterface[];
}

export const projectsSlice = createSlice({
  name: 'projects',
  initialState: <ProjectsStateInterface>{
    search: '',
    section: 'recent',
    team: undefined,
    all: [],
    activeProject: undefined,
    active: null,
    opened: [],
    members: [],
  },
  reducers: {
    setSection(state: ProjectsStateInterface, action: PayloadAction<'personal' | 'recent' | 'archive' | 'favorites'>) {
      state.section = action.payload;
      state.team = undefined;
    },
    setTeam(state: ProjectsStateInterface, action: PayloadAction<string | undefined>) {
      state.section = action.payload === undefined ? 'recent' : undefined;
      state.team = action.payload;
    },
    setSearch(state: ProjectsStateInterface, action: PayloadAction<string>) {
      state.search = action.payload;
    },
    setProjects(state: ProjectsStateInterface, action: PayloadAction<ProjectInterface[]>) {
      state.all = action.payload;
    },
    setActiveProject(state: ProjectsStateInterface, action: PayloadAction<ProjectInterface | null>) {
      state.active = action.payload?.uuid;
      state.activeProject = action.payload;
    },
    openProject(state: ProjectsStateInterface, action: PayloadAction<string>) {
      if (state.opened.indexOf(action.payload) === -1) {
        state.opened.push(action.payload);
      }
      // state.active = action.payload;
      // state.activeProject = state.all.find((project) => project.uuid === action.payload);
    },
    closeProject(state: ProjectsStateInterface, action: PayloadAction<string>) {
      state.active = null;
      state.activeProject = undefined;
      state.opened = without(state.opened, action.payload);
    },
    setProjectMembers(state: ProjectsStateInterface, action: PayloadAction<ProjectMemberInterface[]>) {
      state.members = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(projectsApi.endpoints.createProject.matchFulfilled, (state, action) => {
      state.all.unshift(transformProject(action.payload.data));
    });
    builder.addMatcher(projectsApi.endpoints.findProjects.matchFulfilled, (state, action) => {
      state.all = action.payload.data;
    });
  },
});

export const {setProjects, setTeam, setActiveProject, openProject, closeProject, setSearch, setSection, setProjectMembers} = projectsSlice.actions;

export const projectsReducer = projectsSlice.reducer;
