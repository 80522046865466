import {useEffect, useState} from 'react';
import {List, Modal} from 'semantic-ui-react';
import {without} from 'lodash';
import {useAppDispatch, useAppSelector} from '../hooks';
import {Abbr} from './Abbr';
import {useFindProjectMembersQuery} from '../apis/project-members.api';
import {ProjectMemberInterface, ProjectMemberStatusEnum} from '../interfaces/project-member.interface';
import {
  disableGlobalErrorHandler,
  enableGlobalErrorHandler,
} from '../slices/global.slice';
import {ReportInterface} from '../interfaces/report.interface';
import {useUpdateReportMutation} from '../apis/reports.api';

export function ReportMembersModal({
  report,
  open,
  onClose,
}: {
  report: ReportInterface;
  open: boolean;
  onClose: () => void;
}) {
  const auth = useAppSelector((state) => state.auth);
  const {
    data: projectMembers,
    isLoading,
    isError,
    error,
    refetch,
  } = useFindProjectMembersQuery(report.projectUuid, {skip: !open});
  const [update, updateResult] = useUpdateReportMutation();
  const [members, setMembers] = useState<ProjectMemberInterface[]>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setMembers(projectMembers?.data?.filter((member) => member.status === ProjectMemberStatusEnum.ACTIVE) || []);
  }, [projectMembers]);

  useEffect(() => {
    return () => {
      dispatch(enableGlobalErrorHandler());
    };
  }, []);

  useEffect(() => {
    if (open) {
      refetch();
      dispatch(disableGlobalErrorHandler());
    } else {
      dispatch(enableGlobalErrorHandler());
    }
  }, [open]);

  useEffect(() => {
    // if (addMemberResult?.isSuccess) {
    //   setEmail('');
    // }
  }, [updateResult?.isSuccess]);

  function toggleMember(userUuid: string) {
    let membersUuids = report.memberUuids ? [...report.memberUuids] : [];
    if (membersUuids.indexOf(userUuid) === -1) {
      membersUuids.push(userUuid);
    } else {
      membersUuids = without(membersUuids, userUuid);
    }
    update({
      uuid: report.uuid,
      projectUuid: report.projectUuid,
      memberUuids: membersUuids,
    });
  }

  return (
    <>
      <Modal style={{display: 'flex'}} size="mini" open={open} onClose={() => onClose()} closeOnDimmerClick>
        {/* <Dimmer active={isLoading || updateResult?.isLoading}> */}
        {/*  <LoaderDots /> */}
        {/* </Dimmer> */}
        <Modal.Header>
          Add members to report
          <i className="icon icon-close-window icon--dark" onClick={() => onClose()} />
        </Modal.Header>
        <Modal.Content style={{maxHeight: '60vh', overflowY: 'auto', padding: 0}}>
          <List className="list--hoverable">
            {members.map((member, i) => (
              <List.Item
                key={`member-${i}`}
                className="pl-3 pr-3"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleMember(member.userUuid);
                }}
              >
                <Abbr name={member.userName || member.userEmail || member.invitationEmail || 'Unknown'} />
                <List.Content>
                  <List.Header>{member.userName || member.userEmail || member.invitationEmail}</List.Header>
                  <List.Description>
                    {member.userName !== member.userEmail ? member.userEmail : '\u00A0'}
                  </List.Description>
                </List.Content>
                <List.Content floated="right">
                  {report.memberUuids && report.memberUuids?.indexOf(member.userUuid) !== -1 && (
                    <i className="icon icon-ok icon--dark icon-ok-sm" />
                  )}
                </List.Content>
              </List.Item>
            ))}
          </List>
        </Modal.Content>
      </Modal>
    </>
  );
}
