import {ReactElement} from 'react';

export function TruncatedText({
  children,
  className,
  onClick,
}: {
  children: ReactElement | string;
  className?: string;
  onClick?: (e: any) => void;
}) {
  return (
    <span
      className={className || ''}
      style={{whiteSpace: 'nowrap', flex: '1 1', overflow: 'hidden', textOverflow: 'ellipsis'}}
      onClick={(e) => onClick?.call(null, e)}
    >
      {children}
    </span>
  );
}
