import {Button, Card, Dimmer, Form} from 'semantic-ui-react';
import {useEffect, useState} from 'react';
import {useCreateAccountMutation, useResendVerificationLinkMutation} from '../../apis/auth.api';
import {LoaderDots} from '../homepage/components/LoaderDots';
import {openReporterPage} from '../../common/browser';
import {LoaderLogo} from '../homepage/components/LoaderLogo';

export function SignUp({fcmToken, onSwitch}: {fcmToken: string; onSwitch: () => void}) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [signUp, {error, isError, isSuccess, isLoading: signUpIsLoading}] = useCreateAccountMutation();
  const [resendLink] = useResendVerificationLinkMutation();
  const [loading, setLoading] = useState(false);
  const [terms, setTerms] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const [resendTimeout, setResendTimeout] = useState(0);

  function signUpSubmit() {
    if (!email.trim() || !password.trim() || !password2.trim()) {
      return;
    }
    if (!terms) {
      setTermsError(true);
      return;
    }
    signUp({
      email,
      password,
      password_confirm: password2,
      // app_fcm_token: fcmToken,
    });
  }

  useEffect(() => {
    setTermsError(false);
  }, [terms]);

  useEffect(() => {
    if (resendTimeout > 0) {
      setTimeout(() => setResendTimeout(resendTimeout - 1), 1000);
    }
  }, [resendTimeout]);

  function resend() {
    resendLink({email});
    setResendTimeout(60);
  }

  return (
    <div className="login-page">
      <Card>
        <Card.Content>
          <Dimmer active={signUpIsLoading || loading}>
            <LoaderDots />
          </Dimmer>
          <i className="icon icon-logo icon--xl" />

          {isSuccess ? (
            <>
              <h3 className="mb-3">Your account created. Confirm your email, we have sent you a link</h3>
              <p style={{display: 'flex', flexDirection: 'column', gap: 10}}>
                <a className="link" onClick={() => onSwitch()}>
                  Back to login page
                </a>
                {resendTimeout > 0 ? (
                  <a className="link disabled">You will able to send new email in {resendTimeout} seconds</a>
                ) : (
                  <a className="link" onClick={() => resend()}>
                    Resend verification link
                  </a>
                )}
              </p>
            </>
          ) : (
            <>
              <h3>Create an account</h3>
              <p className="text-secondary mb-4">
                Already have an account?{' '}
                <a className="link" onClick={() => onSwitch()}>
                  Sign in
                </a>
              </p>
              <p className="mb-3">Sign up with social</p>
              <Button.Group>
                <a className="button button--social" href={`${process.env.REACT_APP_ACCOUNT_APP_URL}/auth/google?redirect=${window.location.href.split('?')[0]}`}>
                  <i className="icon icon-google icon--sm mr-1" /> Google
                </a>
                <a className="button button--social" href={`${process.env.REACT_APP_ACCOUNT_APP_URL}/auth/github?redirect=${window.location.href.split('?')[0]}`}>
                  <i className="icon icon-github icon--sm mr-1" /> GitHub
                </a>
              </Button.Group>
              <p className="or">or</p>
              <p className="mb-3">Sign up with email</p>
              <Form className="mb-2">
                <Form.Input
                  className="input--md"
                  value={email}
                  autoFocus
                  onChange={(_e, data) => setEmail(data.value.trim())}
                  placeholder="Email"
                  onKeyUp={(e) => e.code === 'Enter' && signUpSubmit()}
                  error={isError ? ((error as any)?.data?.errors ? (error as any)?.data?.errors?.email : (error as any)?.data?.message) : false}
                />
                <Form.Input
                  type="password"
                  value={password}
                  className="input--md"
                  onChange={(_e, data) => setPassword(data.value.trim())}
                  placeholder="Password"
                  onKeyUp={(e) => e.code === 'Enter' && signUpSubmit()}
                  error={isError && (error as any)?.data?.errors?.password}
                />
                <Form.Input
                  type="password"
                  value={password2}
                  className="input--md"
                  onChange={(_e, data) => setPassword2(data.value.trim())}
                  placeholder="Confirm password"
                  onKeyUp={(e) => e.code === 'Enter' && signUpSubmit()}
                  error={isError && (error as any)?.data?.errors?.password_confirm}
                />
              </Form>
              <p className="login-page__terms mt-3">
                <div className={`checkbox ${termsError ? 'error' : ''}`}>
                  <input id="terms" type="checkbox" checked={terms} onChange={(e) => setTerms(e.currentTarget.checked)} />
                  <label htmlFor="terms">
                    I agree that I have read and accept{' '}<br />the{' '}
                    <a
                      className="link"
                      target="_blank"
                      href={process.env.REACT_APP_TERMS_URL}
                    >
                      Terms of Use
                    </a>
                    {' '}and{' '}
                    <a
                      className="link"
                      target="_blank"
                      href={process.env.REACT_APP_PRIVACY_URL}
                    >
                      Privacy Policy
                    </a>
                    .
                  </label>
                </div>
              </p>
              {termsError && <div className="mb-3 text-dander">You need agree Terms and Policy</div>}
              <Button
                fluid
                content="Create account"
                disabled={!email.trim() || !password.trim() || !password2.trim() || !terms}
                onClick={() => signUpSubmit()}
                primary
              />
            </>
          )}
        </Card.Content>
      </Card>
    </div>
  );
}
