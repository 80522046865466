import {Image} from 'semantic-ui-react';
import {LoaderDots} from '../screens/homepage/components/LoaderDots';

export function Screenshot({src, active, clickable, removable, fullscreenable, onClick, onRemove, onFullscreen}: any) {
  const isVideo = src?.toString().startsWith('data:video') || src?.toString().endsWith('.webm');

  return (
    <div
      className={`screenshot ${clickable ? 'screenshot--clickable' : ''} ${active ? 'screenshot--active' : ''}`}
      onClick={onClick}
    >
      {removable && <i className="icon icon--sm icon-close" onClick={onRemove} />}
      {fullscreenable && <i className="icon icon--sm icon-fullscreen" onClick={onFullscreen} />}
      {active && <i className="icon icon-ok icon--lg" />}
      {isVideo ? <video src={src} /> : src ? <Image src={src} /> : <LoaderDots />}
    </div>
  );
}

function List({children, columns}) {
  return <div className={`screenshot-list ${columns === 2 ? 'screenshot-list--two' : ''}`}>{children}</div>;
}

Screenshot.List = List;
