import {ReactElement, useEffect, useState} from 'react';
import {Button, Modal} from 'semantic-ui-react';

export function ConfirmModal({
  children,
  title,
  text,
  onConfirm,
  onClose,
  danger
}: {
  children: ReactElement;
  title?: string;
  text?: string;
  onConfirm?: () => void;
  onClose?: () => void;
  danger?: boolean;
}) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <span onClick={(e) => {
        e.preventDefault();
        setOpen(true)
      }}>{children}</span>
      <Modal
        size="mini"
        open={open}
        closeOnDimmerClick
        onClose={() => {
          onClose && onClose();
          setOpen(false);
        }}
      >
        <Modal.Header>{title || 'Confirm action'}</Modal.Header>
        <Modal.Content>
          <p>{text || 'Confirm action'}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="Confirm"
            className={danger ? 'button--danger' : 'primary'}
            onClick={() => {
              onConfirm && onConfirm();
              setOpen(false);
            }}
          />
          <Button
            content="Cancel"
            secondary
            onClick={(e) => {
              onClose && onClose();
              setOpen(false);
              e.stopPropagation();
            }}
          />
        </Modal.Actions>
      </Modal>
    </>
  );
}
