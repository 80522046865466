import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {countBy, groupBy} from 'lodash';
import {openProject, setActiveProject} from './projects.slice';
import {ReportInterface} from '../interfaces/report.interface';
import {ReportStatusEnum} from '../interfaces/report-status.enum';
import {trimUrl} from '../common/helpers';
import {reportsApi} from '../apis/reports.api';

export interface ReportsStateInterface {
  reports: ReportInterface[];
  reportsCount: {
    [url: string]: {
      total: number;
      [key: number]: number;
    };
  };
  collapsedPages: {[key: string]: boolean};
  collapsedBoardPages: {[key: string]: boolean};
  pageUrlFilter?: string;
  boardPageUrlFilter?: string;
  statusFilter?: ReportStatusEnum;
  activeReport?: ReportInterface;
  activeReportUuid?: string;
}

const initialState: ReportsStateInterface = {
  reports: [],
  reportsCount: {},
  collapsedPages: {},
  collapsedBoardPages: {},
  pageUrlFilter: undefined,
  boardPageUrlFilter: undefined,
  statusFilter: ReportStatusEnum.NEW,
  activeReport: undefined,
  activeReportUuid: undefined,
};

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setReports(state: ReportsStateInterface, action: PayloadAction<ReportInterface[]>) {
      state.reports = action.payload.filter((report) => report.status !== ReportStatusEnum.DELETED);
      const report = state.reports.find((item) => item.uuid === state.activeReport?.uuid);
      state.activeReportUuid = report?.uuid;
      state.activeReport = report;
      reportsSlice.caseReducers.updateReportsCount(state);
    },
    setPageUrlFilter(state: ReportsStateInterface, action: PayloadAction<string | undefined>) {
      state.pageUrlFilter = action.payload;
      state.activeReportUuid = undefined;
      state.activeReport = undefined;
    },
    setBoardPageUrlFilter(state: ReportsStateInterface, action: PayloadAction<string | undefined>) {
      state.boardPageUrlFilter = action.payload;
    },
    setStatusFilter(state: ReportsStateInterface, action: PayloadAction<ReportStatusEnum>) {
      state.statusFilter = action.payload;
      state.activeReportUuid = undefined;
      state.activeReport = undefined;
    },
    addReport(state: ReportsStateInterface, action: PayloadAction<ReportInterface>) {
      state.reports.push(action.payload);
      state.activeReportUuid = undefined;
      state.activeReport = undefined;
      reportsSlice.caseReducers.updateReportsCount(state);
    },
    setActiveReportUuid(state: ReportsStateInterface, action: PayloadAction<string | undefined>) {
      state.activeReportUuid = action.payload;
      state.activeReport = state.reports.find((report) => report.uuid === action.payload);
    },
    updateReportsCount(state: ReportsStateInterface) {
      const byPage = groupBy(state.reports, 'pageUrl');
      const result = {};
      for (const i in byPage) {
        const key = trimUrl(i);
        const filtered = byPage[i].filter(
          (report) =>
            [ReportStatusEnum.NEW, ReportStatusEnum.IN_WORK, ReportStatusEnum.REVIEW].indexOf(report.status) !== -1
        );
        result[key] = countBy(filtered, 'status');
        result[key].total = filtered.length;
      }
      state.reportsCount = result;
    },
    setCollapsedPages(state: ReportsStateInterface, action: PayloadAction<{[key: string]: boolean}>) {
      state.collapsedPages = action.payload;
    },
    setCollapsedBoardPages(state: ReportsStateInterface, action: PayloadAction<{[key: string]: boolean}>) {
      state.collapsedBoardPages = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(openProject, () => initialState);
    builder.addCase(setActiveProject, () => initialState);
    builder.addMatcher(reportsApi.endpoints.findReports.matchFulfilled, (draft, action) => {
      draft.reports = action.payload.data;
    });
  },
});

export const {
  setReports,
  setPageUrlFilter,
  setStatusFilter,
  addReport,
  setActiveReportUuid,
  setBoardPageUrlFilter,
  setCollapsedPages,
  setCollapsedBoardPages,
} = reportsSlice.actions;

export const reportsReducer = reportsSlice.reducer;
