import {createAsyncThunk} from '@reduxjs/toolkit';
import {LayoutComparisonService} from '../services/layout-comparison.service';
import {DesignLayoutInterface} from '../interfaces/design-layout.interface';

export const getLayouts = createAsyncThunk('layout-comparison/load', async (projectUuid: string) => {
  return LayoutComparisonService.getLayouts(projectUuid);
});

export const createLayout = createAsyncThunk(
  'layout-comparison/create',
  async (payload: {projectUuid: string; data: DesignLayoutInterface}) => {
    return LayoutComparisonService.createLayout(payload.projectUuid, payload.data);
  }
);

export const updateLayout = createAsyncThunk(
  'layout-comparison/update',
  async (payload: {projectUuid: string; layoutUuid: string; data: Partial<DesignLayoutInterface>}) => {
    return LayoutComparisonService.updateLayout(payload.projectUuid, payload.layoutUuid, payload.data);
  }
);

export const deleteLayout = createAsyncThunk(
  'layout-comparison/delete',
  async (payload: {projectUuid: string; layoutUuid: string}) => {
    return LayoutComparisonService.deleteLayout(payload.projectUuid, payload.layoutUuid);
  }
);
