import {ReportInterface} from '../interfaces/report.interface';

export function ReportDateLabel({report}: {report: ReportInterface}) {
  if (!report.dueDate) {
    return <></>;
  }

  return (
    <>
      {new Date(report.dueDate).getTime() < new Date().getTime() && (
        <span className="badge badge--danger">Past due</span>
      )}
      {new Date(report.dueDate).getTime() > new Date().getTime() &&
        new Date(report.dueDate).getTime() - new Date().getTime() < 1000 * 60 * 60 * 24 && (
          <span className="badge badge--warning">Expiring soon</span>
        )}
    </>
  );
}
