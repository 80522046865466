import {ReactElement} from "react";
import {useLocation, Navigate} from "react-router-dom";
import {useAppSelector} from "../hooks";

export function RequireAuth({children}: { children: ReactElement }) {
  const loggedIn = useAppSelector((state) => state.auth.loggedIn);
  let location = useLocation();

  if (!loggedIn) {
    return <Navigate to="/auth" state={{from: location}} replace/>;
  }

  return children;
}
