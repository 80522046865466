import {TeamPlanInterface} from './team-plan-interface';

export enum TeamMemberStatusEnum {
  OWNER = 1,
  ADMIN = 2,
  MEMBER = 3,
  INVITED = 4,
}

export interface TeamInterface {
  uuid: string;
  title: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  userStatus: TeamMemberStatusEnum;
  membersCount: number;
  isPro: boolean;
  plan?: TeamPlanInterface;
}
