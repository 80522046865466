import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ProjectPageInterface} from '../interfaces/project-page.interface';

export interface FavoritePagesStateInterface {
  pages: ProjectPageInterface[];
}
export const favoritePagesSlice = createSlice({
  name: 'favoritePages',
  initialState: <FavoritePagesStateInterface>{
    pages: [],
  },
  reducers: {
    setFavoritePages(state: FavoritePagesStateInterface, action: PayloadAction<ProjectPageInterface[]>) {
      state.pages = action.payload;
    },
  },
});

export const {setFavoritePages} = favoritePagesSlice.actions;

export const favoritePagesReducer = favoritePagesSlice.reducer;
