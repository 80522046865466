import {Transition} from 'semantic-ui-react';
import {Toast} from './Toast';
import {setSyncError} from '../slices/global.slice';
import {useAppDispatch, useAppSelector} from '../hooks';

export function SyncIndicator() {
  const syncStatus = useAppSelector((state) => state.global.sync);
  const syncError = useAppSelector((state) => state.global.syncError);
  const dispatch = useAppDispatch();

  return (
    <div className="sync-indicator">
      {!syncError && (
        <Transition visible={syncStatus} animation="fade" duration={300}>
          <i className="icon icon-cloud-upload icon--secondary" style={{margin: 10}} title="Synchronization..." />
        </Transition>
      )}
      {!!syncError && (
        <>
          <i className="icon icon-cloud-off icon--secondary" style={{margin: 10}} title="Synchronization failed" />
          <Toast isError content={syncError} onClose={() => dispatch(setSyncError(''))} />
        </>
      )}
    </div>
  );
}
