import {Button, Message, Modal} from 'semantic-ui-react';
import {ReactElement, useState, cloneElement, useEffect} from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import {useFeedbackMutation} from '../apis/auth.api';
import {useAppDispatch, useAppSelector} from '../hooks';
import {disableGlobalErrorHandler, enableGlobalErrorHandler} from '../slices/global.slice';

export function FeedbackModal({children}: {children: ReactElement}) {
  const [open, setOpen] = useState(false);
  const [send, result] = useFeedbackMutation();
  const user = useAppSelector((state) => state.auth);
  const [text, setText] = useState('');
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (open) {
      dispatch(disableGlobalErrorHandler());
      setText('');
      result.reset();
    } else {
      dispatch(enableGlobalErrorHandler());
    }
  }, [open]);

  return (
    <>
      {cloneElement(children, {
        onClick: () => setOpen(true),
      })}
      <Modal
        open={open}
        closeOnEscape
        closeOnDimmerClick
        onClose={() => setOpen(false)}
        size="small"
        onClick={(e: any) => e.stopPropagation()}
      >
        <Modal.Header>
          Leave feedback
          <i className="icon icon-close-window icon--dark" onClick={() => setOpen(false)} />
        </Modal.Header>
        {result.isSuccess ? (
          <>
            <Modal.Content>Thanks for your feedback. We will answer you as soon as possible.</Modal.Content>
            <Modal.Actions className={result.isLoading ? 'waiting' : ''}>
              <Button className="button--light" fluid content="Close" onClick={() => setOpen(false)} />
            </Modal.Actions>
          </>
        ) : (
          <>
            <Modal.Content className={result.isLoading ? 'waiting' : ''}>
              {result.isError && <Message error content={(result.error as any).data?.message || 'Unknown error'} />}
              <TextareaAutosize
                placeholder="Write your suggestions, ideas or questions"
                autoFocus
                minRows={4}
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
            </Modal.Content>
            <Modal.Actions className={result.isLoading ? 'waiting' : ''}>
              <Button className="button--light" content="Cancel" onClick={() => setOpen(false)} />
              <Button
                primary
                disabled={!text}
                content="Send"
                onClick={() => {
                  send({
                    email: user.username || 'unknown',
                    name: user.name || user.username || 'unknown',
                    text,
                    source: 'web-app',
                  });
                }}
              />
            </Modal.Actions>
          </>
        )}
      </Modal>
    </>
  );
}
